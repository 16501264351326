// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function get_elementSize(ELEMENT) {
  if (!ELEMENT || ELEMENT instanceof Element === false) return false;
  //////
  const DOMrect = ELEMENT.getBoundingClientRect();
  //////
  const width = DOMrect.width;
  const height = DOMrect.height;
  //////
  return {
    width,
    height,
  };
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default get_elementSize;
