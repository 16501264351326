// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_window_resize(ARGS) {
  // Setup...
  const { flickity } = this.modules;
  const { instance } = flickity;
  const { _name } = ARGS;
  this.logger("eventbus call", [_name], "event", { inline: true });

  // Updt. flickity instance size...
  if (instance) instance.resize();

  // Updt. is_mobile state...
  this.setState({ is_mobile: window.innerWidth < 640 });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
