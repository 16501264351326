// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ——————————————————————————————————————————— IMPORTS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Swup from "swup";
import SwupGiaPlugin from "@swup/gia-plugin";
import SwupjGiaPlugin from "../../plugins/swup-jGia-plugin";
import SwupFragmentPlugin from "@swup/fragment-plugin";
import SwupPreloadPlugin from "@swup/preload-plugin";
import SwupJsPlugin from "@swup/js-plugin";
import eventbus from "../../lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../baseUtilities/logger";
import set_state from "../../baseUtilities/state/set_state";

import convert_stringToHTML from "../../baseUtilities/convert/convert_stringToHTML";
import get_swupPageVisitCount from "../../baseUtilities/get/get_swupPageVisitCount";

function check_fragmIsBeingOpened(SWUPINSTANCE, NAVEVENTDATA) {
  // const { to, from } = NAVEVENTDATA;
  // const fragmPluginInst = SWUPINSTANCE.findPlugin("SwupFragmentPlugin");
  // const fragmRules = SWUPINSTANCE.findPlugin("SwupFragmentPlugin")._parsedRules;
  // const matchingFragmRule = fragmRules.find((rule) => {
  //   rule.matchesTo(to?.url) && rule.matchesFrom(from?.url);
  // });
  // const fragmIsBeingOpened = matchingFragmRule?.type === "open";

  const fragmPluginInst = SWUPINSTANCE.findPlugin("SwupFragmentPlugin");
  const route = { to: NAVEVENTDATA.to.url, from: NAVEVENTDATA?.from.url };
  const fragmVisit = fragmPluginInst?.getFragmentVisit(route);

  return fragmVisit?.name === "openfragment";
}

function check_routeHasSimpleLangVersion(NAVEVENTDATA) {
  // Check data-has-simple-lang on body of incoming page...
  const incomingPageData = NAVEVENTDATA?.to?.html;
  const incomingPageDocBody = convert_stringToHTML(incomingPageData);
  return incomingPageDocBody?.dataset?.hasSimpleLang === "true";
}

function formulate_componentsToLoad(COMPONENTSCONFIG, AVAILABLECOMPONENTS) {
  if (!COMPONENTSCONFIG?.localComponents || !AVAILABLECOMPONENTS) return;
  const enabledComponentTypes = COMPONENTSCONFIG?.localComponents;
  let compToLoad = {};
  enabledComponentTypes.forEach((t) => (compToLoad[t] = AVAILABLECOMPONENTS.find((comp) => comp.type === t)?.script));
  return compToLoad;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class swupAppModule {
  //////////////////////////////// Constructor /////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  constructor(
    CONFIG = {
      logs: { run_withLogs: false, logStyles: {} },
      fragments: { rules: [] },
      eventbus: { callsToAnswer: [], handlers: [] },
      eventHandlers: [],
      theme: false,
      useNewJGiaPlugin: false,
      with_jsAnimPlugin: false,
      pageTransAnimations: [],
    },
    GIACONFIG = { components: { localComponents: [] } },
    OPTIONS = { availableComponents: [] },
    LOCALCOMPONENTCONFIGS = {}
  ) {
    ///////////////////////// Config. //////////////////////////
    ////////////////////////////////////////////////////////////

    this.config = {
      eventbus: CONFIG?.eventbus,
      eventHandlers: CONFIG?.eventHandlers,
    };

    ///////////////////////// Options //////////////////////////
    ////////////////////////////////////////////////////////////

    this.options = {
      ...OPTIONS,
      name: "swup",
      //////
      run_withLogs: CONFIG?.logs?.run_withLogs ?? false,
      logStyles: CONFIG?.logs?.logStyles ?? {},
      //////
      preloading: CONFIG?.preloading ?? {},
      //////
      fragments: CONFIG?.fragments ?? {},
      giaConfig: GIACONFIG ?? {},
      useNewJGiaPlugin: CONFIG?.useNewJGiaPlugin ?? false,
      with_jsAnimPlugin: CONFIG?.with_jsAnimPlugin ?? false,
      pageTransAnimations: CONFIG?.pageTransAnimations ?? [],
      theme: CONFIG?.theme ?? false,
      localComponentConfigs: LOCALCOMPONENTCONFIGS ?? {},
    };

    ////////////////////////// Util. ///////////////////////////
    ////////////////////////////////////////////////////////////

    this.logger = logger.bind(this);

    ////////////////////////// State ///////////////////////////
    ////////////////////////////////////////////////////////////

    this._state = { initialised: false };
    this.setState = set_state.bind(this);
  }

  ////////////////////////////// State management //////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  get state() {
    return this._state;
  }

  set state(state) {
    this.logger("info", ["You should not change state manually. Use setState instead."], "warning");
    this._state = state;
  }

  stateChange(CHANGES) {
    console.log("stateChange", CHANGES);
  }

  /////////////////////////////////// Utils. ///////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  closeFragmentPage() {
    const swupInstance = this.swupInstance;
    if (!swupInstance) return;
    this.logger("info", ["closing fragm. (navigate to home page)"], "action", { inline: true });
    swupInstance.navigate("/");
  }

  ////////////////////////////////// Methods ///////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  init() {
    this.logger("init", ["module"], "default", { inline: true });
    //////
    this.init_swup();
    this.init_eventbus();
    //////
    this.setState({ initialised: true }, false);
  }

  ////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////

  init_customSwupFragmRules(SWUPINSTANCE) {
    this.logger("init", ["custom fragm. rules"], "default", { inline: true });

    // Stop if no swup instance provided...
    if (!SWUPINSTANCE) {
      this.logger("error", ["no swup instance provided"], "error", { inline: true });
      return;
    }

    const fragmPlugin = SWUPINSTANCE.findPlugin("SwupFragmentPlugin");
    const fragmPluginRules = fragmPlugin?._parsedRules;

    // Stop if no fragm. plugin rules found...
    if (!fragmPluginRules) {
      this.logger("error", ["no fragm. plugin rules to modify found"], "error", { inline: true });
      return;
    }

    // Add custom 'type' prop. to each rule (from rules in module config.)...
    fragmPluginRules.forEach((rule, i) => {
      const customRule = this.options.fragments?.rules[i];
      if (!customRule) return;
      rule.type = customRule.type;
    });
  }

  ////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////

  init_swup() {
    // Setup...
    const {
      preloading = {},
      fragments,
      giaConfig,
      useNewJGiaPlugin,
      with_jsAnimPlugin,
      localComponentConfigs = {},
      availableComponents,
      theme,
      pageTransAnimations,
    } = this.options;

    // Gia components (to be passed to plugin)...
    const componentsToLoad = formulate_componentsToLoad(giaConfig?.components, availableComponents);

    // Instance config...
    const fragmPluginInst = new SwupFragmentPlugin({ rules: fragments?.rules });
    const preloadPluginInst = new SwupPreloadPlugin({ preloadVisibleLinks: preloading?.preloadVisibleLinks ?? false });
    const plugins = [fragmPluginInst, preloadPluginInst];
    const giaPluginArgs = { components: componentsToLoad };
    if (useNewJGiaPlugin) plugins.push(new SwupjGiaPlugin({ ...giaPluginArgs, componentConfigs: localComponentConfigs }));
    else plugins.push(new SwupGiaPlugin(giaPluginArgs));
    if (with_jsAnimPlugin) plugins.push(new SwupJsPlugin({ animations: pageTransAnimations }));

    const swupOptions = {
      containers: ["#swup"],
      animationSelector: '[class*="swup-transition-"]',
      animateHistoryBrowsing: true,
      plugins,
    };

    // Create & store instance...
    const swupInstance = new Swup(swupOptions);
    this.swupInstance = swupInstance;

    // Modify swup inst. fragm. plugin rules to include custom 'type' prop...
    this.init_customSwupFragmRules(swupInstance);

    //////////////////////////////
    // Swup visit hook handling //
    //////////////////////////////

    // swupInstance.hooks.on("visit:start", (visit) => {
    // console.log("visit:start", visit);
    // visit.cache.read = false;
    // });

    ///////////////////
    // Hook handling //
    ///////////////////

    // Custom events //
    this.init_customEventHandlers();

    // Instance creation //
    swupInstance.hooks.on("enable", (EVENTDATA) => {
      this.logger("event", ["enable"], "event", { inline: true });

      // Notify components via eventbus...
      eventbus.emit("swup_enable", EVENTDATA);
    });

    // Link click //
    swupInstance.hooks.on("link:click", (EVENTDATA) => {
      this.logger("event", ["link:click"], "event", { inline: true });

      // Record last visited page in state...
      // (to be used by fragment page close-link)
      const previousPageURL = EVENTDATA?.from?.url;
      this.setState({ previousPageURL });

      // Determine if a fragm. page is being opened/closed...
      const fragmIsBeingOpened = check_fragmIsBeingOpened(swupInstance, EVENTDATA);
      EVENTDATA.fragmIsBeingOpened = fragmIsBeingOpened;

      // Notify components via eventbus...
      eventbus.emit("swup_link_click", EVENTDATA);
    });

    // Popstate navigation //
    swupInstance.hooks.on("history:popstate", (EVENTDATA) => {
      this.logger("event", ["popState"], "event", { inline: true });

      // Determine if a fragm. page is being opened/closed...
      const fragmIsBeingOpened = check_fragmIsBeingOpened(swupInstance, EVENTDATA);
      EVENTDATA.fragmIsBeingOpened = fragmIsBeingOpened;

      // Notify components via eventbus...
      eventbus.emit("swup_history_popstate", EVENTDATA);
    });

    // Content replaced //
    swupInstance.hooks.on("content:replace", (EVENTDATA) => {
      this.logger("event", ["content:replace"], "event", { inline: true });

      // Updt. close-link of fragment page...
      // (to link back to page fragm. has been opened from)
      const closeLinkEl = document.getElementById("fragmentPageCloseLink");
      if (closeLinkEl) {
        const previousPageURL = this.state.previousPageURL;
        closeLinkEl.setAttribute("href", previousPageURL);
      }

      // Determ. if fragm. page is being opened/closed...
      const fragmIsBeingOpened = EVENTDATA?.fragmentVisit?.name === "openfragment";
      EVENTDATA.fragmIsBeingOpened = fragmIsBeingOpened;

      // Notify components via eventbus...
      eventbus.emit("swup_content_replace", { ...EVENTDATA });
    });

    // Page view //
    swupInstance.hooks.on("page:view", (EVENTDATA) => {
      this.logger("event", ["page:view"], "event", { inline: true });

      // Determ. if new page has simple lang. version...
      const page_has_simpleLangVersion = check_routeHasSimpleLangVersion(EVENTDATA);

      // Notify components via eventbus...
      eventbus.emit("swup_page_view", { ...EVENTDATA, page_has_simpleLangVersion });
    });

    // Page load //
    swupInstance.hooks.on("page:load", (EVENTDATA) => {
      this.logger("event", ["page:load"], "event", { inline: true });

      // // Determ. if new page has simple lang. version...
      // const page_has_simpleLangVersion = check_routeHasSimpleLangVersion(EVENTDATA);

      // Determine if a fragm. page is being opened/closed...
      const fragmIsBeingOpened = check_fragmIsBeingOpened(swupInstance, EVENTDATA);
      EVENTDATA.fragmIsBeingOpened = fragmIsBeingOpened;

      // Updt. swupPageVisitCount in localStorage...
      const swupPageVisitCount = get_swupPageVisitCount();
      const swupPageVisitCount_new = swupPageVisitCount === null ? 1 : swupPageVisitCount + 1;
      const globalState = JSON.parse(localStorage.getItem("globalState"));
      globalState.swupPageVisitCount = swupPageVisitCount_new;
      localStorage.setItem("globalState", JSON.stringify(globalState));

      // Notify components via eventbus...
      eventbus.emit("swup_page_load", { ...EVENTDATA });
    });

    // visit:end //
    swupInstance.hooks.on("visit:end", (EVENTDATA) => {
      this.logger("event", ["visit:end"], "event", { inline: true });

      // Notify components via eventbus...
      eventbus.emit("swup_visit_end", { ...EVENTDATA });
    });

    ///////////////////////
    // Eventbus handling //
    ///////////////////////

    eventbus.on("FragmPageBg_selfClick", (ARGS) => {
      // Setup...
      const { _name } = ARGS;
      this.logger("eventbus call", [_name, ARGS], "event");

      // Close fragment page...
      const previousPageURL = this.state.previousPageURL ?? ARGS?.parentPageURL;
      this.logger("info", ["closing fragm. (navigate to prev. page)"], "action", { inline: true });
      swupInstance.navigate(previousPageURL);
    });

    eventbus.on("Nav_fragmPageCloseButton_click", (ARGS) => {
      // Setup...
      const { _name } = ARGS;
      this.logger("eventbus call", [_name, ARGS], "event");

      // Close fragment page...
      const previousPageURL = this.state.previousPageURL ?? ARGS?.parentPageURL;
      this.logger("info", ["closing fragm. (navigate to prev. page)"], "action", { inline: true });
      swupInstance.navigate(previousPageURL);
    });

    ///////////////////////////
    // API call registration //
    ///////////////////////////

    eventbus.on("Swup.api.goToPrevPage", (ARGS) => {
      // Setup...
      const { _name } = ARGS;
      const previousPageURL = this.state.previousPageURL ?? ARGS?.parentPageURL;
      this.logger("eventbus call", [_name, ARGS], "event");

      // Guard...
      if (!previousPageURL)
        return this.logger("error", ["Swup.api.goToPrevPage: no previous page URL found"], "error", { inline: true });

      // Go to prev. page...
      this.logger("info", ["going to prev. page"], "action", { inline: true });
      swupInstance.navigate(previousPageURL);
    });

    eventbus.on("Swup.api.goToPage", (ARGS) => {
      // Setup...
      const { url, _name } = ARGS;
      this.logger("eventbus call", [_name, ARGS], "event");

      // Guard...
      if (!url) return this.logger("error", ["Swup.api.goToPage: no url provided"], "error", { inline: true });

      // Go to page...
      this.logger("info", ["going to page", url], "action", { inline: true });
      swupInstance.navigate(url);
    });
  }

  ////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////

  init_eventbus() {
    // Setup...
    const { callsToAnswer, handlers } = this.config.eventbus ?? {};
    this.logger("init", ["eventbus"], "default", { inline: true });

    // Stop, if no custom calls to answer or no custom handlers provided...
    if (!callsToAnswer || callsToAnswer.length === 0 || !handlers || handlers.length === 0) {
      const message = ["eventbus", "no custom calls to answer or no handlers provided", "stopping init."];
      this.logger("init", message, "warning", { inline: true });
      return;
    }

    // Handling of eventbus calls...
    callsToAnswer.forEach((call) => {
      const handler = handlers.find((handler) => handler.id === call)?.handler;
      if (!handler) return;
      eventbus.on(call, handler.bind(this));
    });
  }

  ////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////

  init_customEventHandlers() {
    // Setup...
    const { eventHandlers } = this.config;
    this.logger("init", ["custom event hdl."], "default", { inline: true });

    // Stop, if no handlers provided...
    if (!eventHandlers || eventHandlers.length === 0) {
      const message = ["custom event hdl.", "no handlers provided", "stopping init."];
      this.logger("init", message, "warning", { inline: true });
      return;
    }

    // Assign handlers to swup instance hooks...
    eventHandlers.forEach((handler) => {
      const { id, handler: handlerFn, config = {} } = handler;
      if (!id || !handlerFn) return;
      this.swupInstance.hooks.on(id, handlerFn, config);
    });
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default swupAppModule;
