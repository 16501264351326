// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_status", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`status: ${CHANGE}`],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_status(CHANGES) {
  if (!("status" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { status } = CHANGES;
  const { statusIndicators_valid, submitButton } = this.ref;
  const is_valid = status === "valid";
  const is_submitting = status === "submitting";
  this.logger("state-change", loggerMsgs.stateChange(status), "default", logArgs);

  // Show/Hide status indicator...
  statusIndicators_valid.forEach((ind) => ind.setAttribute("data-is-active", is_valid));

  // If switching to valid state, hide invalid-field notification...
  if (is_valid)
    this.setState({
      notifStates: {
        invalidField: { is_active: false },
        submissionSuccess: { is_active: this.state.notifStates.submissionSuccess.is_active },
        gmail: { is_active: this.state.notifStates.gmail.is_active },
      },
    });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
