// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_links", inline: true };
const loggerMsgs = {
  initStart: ["init. links"],
  initSuccess: ["init. links", "init. success"],
  noValidDOMEl: ["init. links", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_links() {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { links } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (links === undefined) return this.cancel_featInit(loggerMsgs.noValidDOMEl);
  if (links.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  ////////////////////// Init. link-states setting ///////////////////////
  ////////////////////////////////////////////////////////////////////////

  const linkStates = links.map((el) => {
    const target = el.href.split("/").pop();
    const { id: id_raw, parentId, isActive, isParent } = el.dataset;
    const id = id_raw.startsWith("/") ? id_raw.slice(1) : id_raw; // ← remove leading slash from ID
    const alias = el.dataset.alias != "" ? el.dataset.alias : null;
    const is_active = isActive === "true";
    const is_parent = isParent === "true";
    return { el, target, id, parentId, alias, is_active, is_parent };
  });
  this.setState({ linkStates });

  ////////////////////////////// Event hdl. //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // links.forEach((el) => {
  // el.addEventListener("mouseenter", eh_link_mouseEnter.bind(this, el));
  // el.addEventListener("mouseleave", eh_link_mouseLeave.bind(this, el));
  // });

  /////////////////////////////// Conclude ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
