// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Masonry from "masonry-layout";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. desandroGrid"],
  initSuccess: ["init. desandroGrid", "init. success"],
  noValidDOMEl: ["init. desandroGrid", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_desandroGrid() {
  // Setup...
  const { gridWrapper } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (!this.validate_refEL(gridWrapper)) return this.cancel_featureInit(loggerMsgs.noValidDOMEl);

  // Create grid instance...
  const gridInstance = new Masonry(gridWrapper, {
    columnWidth: ".gridSizer",
    itemSelector: ".gridItem",
    gutter: ".gutterSizer",
    transitionDuration: 0,
  });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
  return gridInstance;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
