// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_submit from "../eventHandlers/eh_submit.js";
import eh_withCompanyField_change from "../eventHandlers/eh_withCompanyField_change.js";
import eh_confirmOptField_change from "../eventHandlers/eh_confirmOptField_change.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const msgs = {
  start: ["init. form"],
  end: ["init. form", "init. success"],
  no_el: ["init. form", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_form() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const compEl = this.element;
  const { formEl, formField_withCompany } = this.ref;
  const { with_companySignUpOption } = this.options;
  this.logger("init", msgs.start, "action");

  // Guard...
  if (!this.validate_refEL(formEl)) return this.cancel_featureInit(msgs.no_el);

  ////////////////////////////////////////// Event hdl. //////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Assign on-submit event handler...
  formEl.onsubmit = eh_submit.bind(this);

  // Listen to toggling of "with company" checkbox...
  if (with_companySignUpOption) formField_withCompany.addEventListener("change", eh_withCompanyField_change.bind(this));

  //////////////////////////////////// Confirm. options init. ////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Get confirm. option fields...
  const confirmOptFields = compEl.querySelectorAll("[g-ref*='formField_confirmOption']");

  // Listen to confirm. option field changes...
  confirmOptFields.forEach((fieldEl) => fieldEl.addEventListener("change", eh_confirmOptField_change.bind(this)));

  /////////////////////////////////////////// Conclude ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  this.logger("init", msgs.end, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
