// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_navButton_click from "../eventHandlers/eh_navButton_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_navButtons", inline: true };
const loggerMsgs = {
  initStart: ["init. navButtons"],
  initSuccess: ["init. navButtons", "init. success"],
  noValidDOMEl: ["init. navButtons", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_navButtons() {
  // Setup...
  const { navButton_prev, navButton_next } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if ([navButton_prev, navButton_next].every((el) => !this.validate_refEl(el)))
    return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Event hdl...
  if (this.validate_refEl(navButton_prev))
    navButton_prev.addEventListener("click", eh_navButton_click.bind(this, navButton_prev));
  if (this.validate_refEl(navButton_next))
    navButton_next.addEventListener("click", eh_navButton_click.bind(this, navButton_next));

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
