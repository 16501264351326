// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_numberField_input from "../eventHandlers/eh_numberField_input.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. number-fields"],
  initSuccess: ["init. number-fields", "init. success"],
  noValidDOMEl: ["init. number-fields", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_numberFields() {
  // Setup...
  const { fields } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (fields.every((el) => !this.validate_refEL(el))) return this.cancel_featureInit(loggerMsgs.noValidDOMEl);

  // Listen to input events on number fields...
  const numberFields = fields.filter((el) => el.dataset.type === "number");
  if (!numberFields.length) return this.cancel_featureInit(loggerMsgs.noNumberFields);
  numberFields.forEach((el) => el.addEventListener("input", eh_numberField_input.bind(this, el)));

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
