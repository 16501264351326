// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_notifStates", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`notif. states:`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_notifStates(CHANGES) {
  if (!("notifStates" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { notifStates } = CHANGES;
  const { notification_invalidField, notification_submissionSuccess, notification_gmail } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(notifStates), "default", { ...logArgs, inline: false });

  // Hide/show notifications...
  notification_invalidField.setAttribute("data-is-active", notifStates.invalidField.is_active);
  notification_submissionSuccess.setAttribute("data-is-active", notifStates.submissionSuccess.is_active);
  notification_gmail.setAttribute("data-is-active", notifStates.gmail.is_active);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
