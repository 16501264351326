// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`change: ${CHANGE}`],
  noValidDOMEl: ["selected slide index state-change hdl.", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_selectedSlideIndex(CHANGES) {
  // Setup...
  const { slideCounter, buttons } = this.ref;
  const { selectedSlideIndex } = CHANGES;
  this.logger("state-change", loggerMsgs.stateChange(selectedSlideIndex), "default", { inline: true });

  // Guard...
  if (!this.validate_refEL(slideCounter)) return this.cancel_featureInit(loggerMsgs.noValidDOMEl);

  // Updt. slide counter text...
  slideCounter.querySelector("div span").textContent = selectedSlideIndex;

  // If on first/last slide, disable/enable prev/next buttons...
  const is_onFirst = selectedSlideIndex === 1;
  const is_onLast = selectedSlideIndex === this.ref.slides.length;
  const prevBtns = buttons.filter((btn) => btn.dataset.direction === "<");
  const nextBtns = buttons.filter((btn) => btn.dataset.direction === ">");
  if (prevBtns) prevBtns.forEach((el) => el.setAttribute("data-is-disabled", is_onFirst));
  if (nextBtns) nextBtns.forEach((el) => el.setAttribute("data-is-disabled", is_onLast));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
