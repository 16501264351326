// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

/**
 * Updates the submenu states based on the active link.
 *
 * @param   {Array}  SUBMENUSTATES_old - The array of old submenu states.
 * @param   {Object} ACTIVELINK        - The active link object.
 * @returns {Array}  The updated submenu states.
 *
 * @throws  {Error}  If no valid submenu states are provided or if no active link is provided.
 *
 * @example
 *
 * Old submenu states
 * const SUBMENUSTATES_old = [
 *   { parentId: "parent1", is_active: false },
 *   { parentId: "parent2", is_active: false },
 *   { parentId: "parent3", is_active: false }
 * ];
 *
 * Active link
 * const ACTIVELINK = { id: "parent2/child1" };
 *
 * Updt. submenu states
 * const submenuStates_new = make_updtSubmenuStates(SUBMENUSTATES_old, ACTIVELINK);
 * Output: [
 *   { parentId: "parent1", is_active: false },
 *   { parentId: "parent2", is_active: true },
 *   { parentId: "parent3", is_active: false }
 * ]
 */

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.make_updtSubmenuStates", inline: true };
const loggerMsgs = {
  eventStart: ["Nav-proto", "API", "make_updtSubmenuStates"],
  noValidSubmenuStates: ["make_updtSubmenuStates()", "no valid submenuStates provided"],
  noActiveLink: ["make_updtSubmenuStates()", "no active link provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function make_updtSubmenuStates(SUBMENUSTATES_old, ACTIVELINK) {
  // Setup...
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Guard...
  if (!SUBMENUSTATES_old || !SUBMENUSTATES_old.length || SUBMENUSTATES_old.constructor !== Array)
    return this.logger("error", loggerMsgs.noValidSubmenuStates, "error", { inline: true });
  if (!ACTIVELINK || ACTIVELINK.constructor !== Object)
    return this.logger("error", loggerMsgs.noActiveLink, "error", { inline: true });

  //////////////////// Updt. submenu states creation /////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Determ. active link parent...
  const activeLinkParts = ACTIVELINK.id.split("/");
  const activeLink_isSubmenuLink = activeLinkParts.length > 1;
  const activeLinkParent = activeLink_isSubmenuLink ? activeLinkParts[0] : null;

  // Create updated submenu states...
  const submenuStates_new = SUBMENUSTATES_old.map((submenuState) => {
    const { parentId } = submenuState;
    const is_open = parentId === activeLinkParent;
    const is_active = parentId === ACTIVELINK.id || parentId === activeLinkParent;

    // Determ. if passive by checking if any submenu is active & link does not belong to it...
    const has_activeSubmenu = SUBMENUSTATES_old.some(
      (smS) => smS.parentId === ACTIVELINK.id || smS.parentId === activeLinkParent
    );
    const is_passive = !is_active && !is_open && has_activeSubmenu;

    return { ...submenuState, is_open, is_active, is_passive };
  });

  return submenuStates_new;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
