// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function check_link_isPassive(LINKSTATE, ACTIVE_LINK) {
  const { id, parentId } = LINKSTATE;
  const { id: activeLinkId, parentId: activeLinkParentId } = ACTIVE_LINK;
  const is_activeLink = id === activeLinkId;
  const is_parentOfActiveLink = activeLinkParentId ? id === activeLinkParentId : false;
  const is_childOfActiveLink = parentId === activeLinkId;
  const is_siblingOfActiveLink = parentId ? parentId === activeLinkParentId : false;
  const is_passive = !is_activeLink && !is_parentOfActiveLink && !is_childOfActiveLink && !is_siblingOfActiveLink;
  return is_passive;
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_linkStates", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`link states:`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_linkStates(CHANGES) {
  if (!("linkStates" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { linkStates } = CHANGES;
  const { submenuStates } = this.state;
  const activeLink = linkStates.find((linkState) => linkState.is_active);
  this.logger("state-change", loggerMsgs.stateChange(linkStates), "default", { ...logArgs, inline: false });

  // Updt. submenu-states (set submenu corresp. to currently active link active)...
  let has_activeSubmenu = false;
  if (submenuStates && activeLink) {
    const submenuStates_new = this.api.make_updtSubmenuStates(submenuStates, activeLink);
    this.setState({ submenuStates: submenuStates_new });
    has_activeSubmenu = submenuStates_new ? submenuStates_new.some((smS) => smS.is_active) : false;
  }

  // Toggle active attr. on link elements...
  linkStates.forEach((lS) => {
    const { el, is_active } = lS;

    // Determ. if link is passive (i.e. some submenu is active & link does not belong to it)...
    const is_passive = has_activeSubmenu ? check_link_isPassive(lS, activeLink) : false;

    el.setAttribute("data-is-active", is_active);
    el.setAttribute("data-is-passive", is_passive);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
