// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`change: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeFilter(CHANGES) {
  // Setup...
  const { desandroGrid } = this.modules;
  const { gridItems } = this.ref;
  const { activeFilter } = CHANGES;
  this.logger("state-change", loggerMsgs.stateChange(activeFilter), "default", { inline: true });

  // Updt. grid item visibility...
  gridItems.forEach((GRIDITEM) => {
    if (!GRIDITEM.hasAttribute("data-tags") || activeFilter === "alle") return GRIDITEM.setAttribute("data-is-hidden", false);
    let tags = GRIDITEM.dataset.tags;
    tags = JSON.parse(tags);
    GRIDITEM.setAttribute("data-is-hidden", !tags.includes(activeFilter));
  });

  // Updt. grid item positions...
  if (!desandroGrid.instance) return;
  desandroGrid.instance.layout();
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
