// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

/**
 * Updates the submenu states based on the active link.
 *
 * @param   {Array}  SUBMENUSTATES_old - The array of old submenu states.
 * @param   {Object} ACTIVELINK        - The active link object.
 * @returns {Array}  The updated submenu states.
 *
 * @throws  {Error}  If no valid submenu states are provided or if no active link is provided.
 *
 * @example
 *
 * // Old submenu states
 * const SUBMENUSTATES_old = [
 *   { parentId: "parent1", is_active: false },
 *   { parentId: "parent2", is_active: false },
 *   { parentId: "parent3", is_active: false }
 * ];
 *
 * // Active link
 * const ACTIVELINK = { id: "parent2/child1" };
 *
 * // Updt. submenu states
 * const submenuStates_new = make_updtSubmenuStates(SUBMENUSTATES_old, ACTIVELINK);
 * // Output: [
 * //   { parentId: "parent1", is_active: false },
 * //   { parentId: "parent2", is_active: true },
 * //   { parentId: "parent3", is_active: false }
 * // ]
 */

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMessages = {
  noValidSubmenuStates: ["make_updtSubmenuStates()", "no valid submenuStates provided"],
  noActiveLink: ["make_updtSubmenuStates()", "no active link provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function make_updtSubmenuStates(SUBMENUSTATES_old, ACTIVELINK) {
  // Guard...
  if (!SUBMENUSTATES_old || !SUBMENUSTATES_old.length || SUBMENUSTATES_old.constructor !== Array)
    return this.logger("error", loggerMessages.noValidSubmenuStates, "error", { inline: true });
  if (!ACTIVELINK || ACTIVELINK.constructor !== Object)
    return this.logger("error", loggerMessages.noActiveLink, "error", { inline: true });

  // Create updated submenu-states...
  const activeLinkParts = ACTIVELINK.id.split("/");
  const activeLink_isSubmenuLink = activeLinkParts.length > 1;
  const activeLinkParent = activeLink_isSubmenuLink ? activeLinkParts[0] : null;

  const submenuStates_new = SUBMENUSTATES_old.map((submenuState) => {
    const { parentId } = submenuState;
    const is_active = parentId === ACTIVELINK.id || parentId === activeLinkParent;
    return { ...submenuState, is_active };
  });

  return submenuStates_new;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
