// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function set_imgCaptionWidths() {
  const imgCaptions = document.querySelectorAll("figcaption.img-caption");
  if (!imgCaptions) return console.warn("set_imgCaptionWidths() → No img. caption el. found.");

  // Set caption el. width to height of figure el. (parent)...
  imgCaptions.forEach((caption) => {
    const figure = caption.parentElement;
    const figureHeight = figure.clientHeight;
    caption.style.width = `${figureHeight}px`;
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
