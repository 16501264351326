// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_textareaField_input", inline: true };
const loggerMsgs = {
  eventStart: ["textarea-field has changed"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_textareaField_input(FIELDEL) {
  // Setup...
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Updt. .characterCount el...
  const characterCountEl = FIELDEL.querySelector(".characterCount");
  if (!characterCountEl) return;
  const inputEl = FIELDEL.querySelector("textarea");
  characterCountEl.innerHTML = inputEl.value.length;

  // Updt. character count el. color...
  const charCountWrapper = characterCountEl.parentElement;
  const characterLimit = FIELDEL.dataset.maxLength;
  const is_overMax = inputEl.value.length > characterLimit;
  charCountWrapper.setAttribute("data-is-over-max", is_overMax);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
