// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_button_click from "../eventHandlers/eh_button_click";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. buttons"],
  initSuccess: ["init. buttons", "init. success"],
  noValidDOMEl: ["init. buttons", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_buttons() {
  // Setup...
  const { buttons } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (buttons.every((btn) => !this.validate_refEL(btn))) return this.cancel_featureInit(loggerMsgs.noValidDOMEl);

  // Listen to click-event on buttons...
  buttons.forEach((btn) => btn.addEventListener("click", eh_button_click.bind(this, btn)));

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
