// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`privacyNoticeChecked: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_privacyNoticeChecked(CHANGES) {
  if (!("privacyNoticeChecked" in CHANGES)) return;

  // Setup...
  const { privacyNoticeChecked } = CHANGES;
  this.logger("state-change", loggerMsgs.stateChange(privacyNoticeChecked), "default", { inline: true });

  // Enabled/Disable submit button...
  this.element.setAttribute("data-privacy-notice-is-checked", privacyNoticeChecked);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
