// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_currentPage", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`curr. page: ${CHANGE}`],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_currentPage(CHANGES) {
  if (!("currentPage" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { currentPage } = CHANGES;
  const { formPages, navButton_prev, navButton_next, submitButton, pageButtons } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(currentPage), "default", logArgs);

  // Toggle active attr. on nav buttons...
  const maxPage = 3;
  navButton_prev.setAttribute("data-is-active", currentPage === 1 ? "false" : "true");
  navButton_next.setAttribute("data-is-active", currentPage === maxPage ? "false" : "true");

  // Updt. is-current attr. on page buttons...
  pageButtons.forEach((b, i) => b.setAttribute("data-is-current", i + 1 === currentPage));

  // Toggle active attr. on form pages...
  formPages.forEach((p, i) => p.setAttribute("data-is-active", i + 1 === currentPage));

  // Toggle hidden attr. on submit button...
  submitButton.setAttribute("data-is-hidden", currentPage != maxPage);

  // // Scroll to top of page...
  window.scrollTo(0, 0);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
