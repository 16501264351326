// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_submit from "../eventHandlers/eh_submit";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. submit"],
  initSuccess: ["init. submit", "init. success"],
  noValidDOMEl: ["init. submit", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_submit() {
  // Setup...
  const { submitButton } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (!this.validate_refEL(submitButton)) return this.cancel_featureInit(loggerMsgs.noValidDOMEl);

  // Listen to click events on submit button...
  submitButton.addEventListener("click", eh_submit.bind(this));

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
