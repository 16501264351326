// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_pageTitle", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`page title: ${CHANGE}`],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

// const possibleStateValues = ["foo", "bar"];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_pageTitle(CHANGES) {
  if (!("pageTitle" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { pageTitle } = CHANGES;
  const { pageTitleTextEl, mobilePageTitleTextEl } = this.ref;
  console.log({ pageTitleTextEl, mobilePageTitleTextEl });
  this.logger("state-change", loggerMsgs.stateChange(pageTitle), "default", logArgs);

  // Updt. page title text el...
  if (pageTitleTextEl) pageTitleTextEl.innerHTML = pageTitle;
  if (mobilePageTitleTextEl) mobilePageTitleTextEl.innerHTML = pageTitle;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
