// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMessages = {
  stateChange: (CHANGE) => [`active grid item index: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeGridItem(CHANGES) {
  // Setup...
  const { gridItems } = this.ref;
  const { activeGridItem } = CHANGES;
  this.logger("state-change", loggerMessages.stateChange(activeGridItem), "default", { inline: true });

  // Updt. active attr. of grid items...
  gridItems.forEach((item) => {
    const { index } = item.dataset;
    item.setAttribute("data-is-active", index === activeGridItem);

    const is_passive = index !== activeGridItem && activeGridItem !== "null";
    item.setAttribute("data-is-passive", is_passive);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
