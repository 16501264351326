// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_slideChange from "../eventHandlers/eh_slideChange.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. flickity"],
  initSuccess: ["init. flickity", "init. success"],
  noValidDOMEl: ["init. flickity", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_flickity() {
  // Setup...
  const { slidesWrapper } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (!this.validate_refEL(slidesWrapper)) return this.cancel_featureInit(loggerMsgs.noValidDOMEl);

  // Create Flickity instance...
  const flickityInstance = new Flickity(slidesWrapper, {
    wrapAround: false,
    cellAlign: "left",
    draggable: window.innerWidth < 640,
    prevNextButtons: false,
    pageDots: false,
    lazyLoad: 1,
    //////
    on: {
      ready: () => this.init_buttons(),
      change: () => eh_slideChange.bind(this)(),
    },
  });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
  return flickityInstance;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
