// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import get_elementSize from "../../../../../app/baseUtilities/get/get_elementSize";
import get_elementStyleValue from "../../../../../app/baseUtilities/get/get_elementStyleValue";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`open item index: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_openItem(CHANGES) {
  if (!CHANGES.openItem) return;

  // Setup...
  const { items } = this.ref;
  const { openItem } = CHANGES;
  const openItemIndex = parseInt(openItem);
  this.logger("state-change", loggerMsgs.stateChange(openItemIndex), "default", { inline: true });

  // Set has-open-item data attr. (hides/reveals header)...
  this.element.setAttribute("data-has-open-item", openItem != "null");

  // Open/Close items...
  items.forEach((item, i) => {
    // Setup...
    const itemPaddingTop = get_elementStyleValue(item, "padding-top");
    const itemContentWrapper = item.querySelector("[data-ref='contentWrapper']");

    // Set is-open attr...
    const is_open = i === openItemIndex;
    item.setAttribute("data-is-open", is_open);

    // Set item height (full/closed)...
    const fullItemHeight = get_elementSize(itemContentWrapper).height + parseInt(itemPaddingTop) * 2 + 3;
    item.style.setProperty("height", is_open ? `${fullItemHeight}px` : "");
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
