// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function resetForm() {
  const {
    formField_formOfAddress,
    formField_title,
    formField_firstName,
    formField_lastName,
    formField_email,
    formField_withCompany,
    formField_formOfAddress_company,
    formField_title_company,
    formField_firstName_company,
    formField_lastName_company,
  } = this.ref;

  const textFields = [
    formField_firstName,
    formField_lastName,
    formField_email,
    formField_firstName_company,
    formField_lastName_company,
  ];

  const selectFields = [formField_formOfAddress, formField_title, formField_formOfAddress_company, formField_title_company];

  const checkboxFields = [formField_withCompany];

  for (let field of textFields) field.value = "";
  for (let field of selectFields) field.selectedIndex = 0;
  for (let field of checkboxFields) if (field.checked) field.click();

  // Reset confirm. option fields...
  const compEl = this.element;
  const confirmOptFields = compEl.querySelectorAll("[g-ref*='formField_confirmOption']");
  confirmOptFields.forEach((fieldEl) => (fieldEl.checked = false));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
