// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  eventStart: ["number-field has changed"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_numberField_input(FIELDEL, EVENTDATA) {
  // Setup...
  const justValidateInstance = this.modules.justValidate.instance;
  const { submitAttempts } = this.state;
  const { fields } = this.ref;
  const { mustBeSumOf, isSummandOf } = FIELDEL.dataset;
  this.logger("event", loggerMsgs.eventStart, "event", { inline: true });

  // Prevent input of decimal dots...
  const key = EVENTDATA.charCode ? EVENTDATA.charCode : EVENTDATA.keyCode;
  if (key === 46) EVENTDATA.preventDefault();

  // If field value must be sum of other fields, validate...
  const summandIDs = JSON.parse(mustBeSumOf);
  if (summandIDs) {
    const inputEl = FIELDEL.querySelector("input");
    const summands = fields.filter((el) => JSON.parse(mustBeSumOf).includes(el.dataset.id));
    const sum = summands.reduce((acc, cur) => acc + Number(cur.querySelector("input").value), 0);

    // Mark field as invalid if sum of other fields is not equal to field value...
    FIELDEL.setAttribute("data-is-invalid", sum !== Number(inputEl.value));
  }

  // If field value is summand of other fields, validate field it is summand of...
  if (isSummandOf) {
    const sumField = fields.find((el) => el.dataset.id === isSummandOf);
    const summands = fields.filter((el) => JSON.parse(sumField.dataset.mustBeSumOf).includes(el.dataset.id));
    const sum = summands.reduce((acc, cur) => acc + Number(cur.querySelector("input").value), 0);
    const sum_expected = Number(sumField.querySelector("input").value);
    const sumIsCorrect = sum === sum_expected;

    // Mark sumField as invalid if sum of summands is not equal to sumField value...
    sumField.setAttribute("data-is-invalid", !sumIsCorrect);

    // Revalidate sumField (after 1st submit attempt)...
    if (submitAttempts > 0) justValidateInstance.revalidateField(sumField.querySelector("input"));
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
