// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger";
import validate_refEL from "../../../../app/baseUtilities/validate/validate_refEl.js";
import cancel_featureInit from "../../../../app/baseUtilities/cancel/cancel_featureInit";
import cancel_ebh from "../../../../app/baseUtilities/cancel/cancel_ebh.js";
import make_updtLinkStates from "./util/make_updtLinkStates.js";
import make_updtSubmenuStates from "./util/make_updtSubmenuStates.js";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_searchBar from "./init/init_searchBar.js";
import init_links from "./init/init_links.js";
import init_submenus from "./init/init_submenus.js";
import init_mobileMenuButton from "./init/init_mobileMenuButton.js";

// ————————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS ————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_swup_page_load from "./eventbusHandlers/ebh_swup_page_load.js";
import ebh_window_resize from "./eventbusHandlers/ebh_window_resize.js";
import ebh_window_scroll from "./eventbusHandlers/ebh_window_scroll.js";
import ebh_document_click from "./eventbusHandlers/ebh_document_click.js";

import stChH_linkStates from "./stateChangeHandlers/stChH_linkStates.js";
import stChH_submenuStates from "./stateChangeHandlers/stChH_submenuStates.js";
import stChH_searchBarState from "./stateChangeHandlers/stChH_searchBarState.js";
import stChH_hidden from "./stateChangeHandlers/stChH_hidden.js";
import stChH_pageTitleHidden from "./stateChangeHandlers/stChH_pageTitleHidden.js";
import stChH_searchBarHidden from "./stateChangeHandlers/stChH_searchBarHidden.js";
import stChH_pageTitle from "./stateChangeHandlers/stChH_pageTitle.js";
import stChH_mobileMenuState from "./stateChangeHandlers/stChH_mobileMenuState.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Options with case-sensitive keys that are not to be
// automatically extracted from the options arg. but be
// assigned to an option key manually to preserve its case
// (Kirby CMS converts all fields/option keys to lowercase)
// (see constructor).

const manualOptionKeys = ["optionkey"];

//  Default values for manually extracted options
//  (see constructor, in case specific option has not been provided
//  in comp. config.).

const defaultOptions = {
  optionkey: { foo: "bar" },
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class Nav extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element, options) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {
      pageTitleWrapper: null,
      pageTitleTextEl: null,

      submenus: [],
      links: [],

      searchBar: null,
      searchBarButton: null,
      searchBarInputWrapper: null,

      mobilePageTitleTextEl: null,
      mobileMenuButton: null,
      mobileMenuWrapper: null,
    };

    //////////// Options /////////////
    //////////////////////////////////

    // Get options not to be manually extracted from the options arg...
    const autoOptions = {};
    for (const key in options) if (!manualOptionKeys.includes(key)) autoOptions[key] = options[key];

    // Get navigation data from local storage...
    const navData = JSON.parse(localStorage.getItem("navData"));
    const mainMenuLinkData = navData?.mainMenu?.links;

    this.options = {
      name: "Nav",
      version: element.getAttribute("g-version") ?? "1",
      // optionKey: options.optionkey,
      ...autoOptions,
      linkData: mainMenuLinkData,
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);
    this.validate_refEL = validate_refEL.bind(this);
    this.cancel_featureInit = cancel_featureInit.bind(this);
    this.cancel_ebh = cancel_ebh.bind(this);
    this.make_updtLinkStates = make_updtLinkStates.bind(this);
    this.make_updtSubmenuStates = make_updtSubmenuStates.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    this.init_searchBar = init_searchBar.bind(this);
    this.init_links = init_links.bind(this);
    this.init_submenus = init_submenus.bind(this);
    this.init_mobileMenuButton = init_mobileMenuButton.bind(this);

    ///////////// Modules //////////////
    ////////////////////////////////////

    // ...

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_document_click = ebh_document_click.bind(this);
    this.ebl_swup_page_load = ebh_swup_page_load.bind(this);
    this.ebl_window_resize = ebh_window_resize.bind(this);
    this.ebl_window_scroll = ebh_window_scroll.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_mobileMenuState = stChH_mobileMenuState.bind(this);
    this.stChL_pageTitle = stChH_pageTitle.bind(this);
    this.stChL_searchBarHidden = stChH_searchBarHidden.bind(this);
    this.stChL_hidden = stChH_hidden.bind(this);
    this.stChL_pageTitleHidden = stChH_pageTitleHidden.bind(this);
    this.stChL_searchBarState = stChH_searchBarState.bind(this);
    this.stChL_linkStates = stChH_linkStates.bind(this);
    this.stChL_submenuStates = stChH_submenuStates.bind(this);

    ////// Custom event handlers ///////
    // (To be passed to parent class) //

    // ...

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    this.logger("info", ["mounting"], "action", { inline: true });
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    this.logger("info", ["unmounting"], "action", { inline: true });

    ///////////////////////////////////
    // Unregister eventbus listeners //
    ///////////////////////////////////

    eventbus.off("swup_page_load", this.ebl_swup_page_load);
    eventbus.off("window_resize", this.ebl_window_resize);
    eventbus.off("window_scroll", this.ebl_window_scroll);
    eventbus.off("document_click", this.ebl_document_click);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.init_mobileMenuButton();
    const version = this.options.version;

    // Version 1 //
    if (version == 1) this.init_submenus();

    // All versions //
    this.init_links();
    this.init_searchBar();
    this.init_states();
    this.init_eventbus();
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    this.logger("init", ["states"], "action", { inline: true });
    this.setState({ is_mobile: window.innerWidth < 640 });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { inline: true });

    /////////////////////////////////
    // Register eventbus listeners //
    /////////////////////////////////

    eventbus.on("swup_page_load", this.ebl_swup_page_load);
    eventbus.on("window_resize", this.ebl_window_resize);
    eventbus.on("window_scroll", this.ebl_window_scroll);
    eventbus.on("document_click", this.ebl_document_click);
  }

  //////////////////////////// State Changes /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    ///////// mobileMenuState /////////
    ////////////////////////////////////

    if ("mobileMenuState" in CHANGES) this.stChL_mobileMenuState(CHANGES);

    //////////// pageTitle ////////////
    ////////////////////////////////////

    if ("pageTitle" in CHANGES) this.stChL_pageTitle(CHANGES);

    ///////// searchBarHidden /////////
    ////////////////////////////////////

    if ("searchBarHidden" in CHANGES) this.stChL_searchBarHidden(CHANGES);

    ///////// pageTitleHidden /////////
    ////////////////////////////////////

    if ("pageTitleHidden" in CHANGES) this.stChL_pageTitleHidden(CHANGES);

    ////////////// hidden //////////////
    ////////////////////////////////////

    if ("hidden" in CHANGES) this.stChL_hidden(CHANGES);

    ////////// searchBarState //////////
    ////////////////////////////////////

    if ("searchBarState" in CHANGES) this.stChL_searchBarState(CHANGES);

    ////////// submenuStates //////////
    ////////////////////////////////////

    if ("submenuStates" in CHANGES) this.stChL_submenuStates(CHANGES);

    //////////// linkStates ////////////
    ////////////////////////////////////

    if ("linkStates" in CHANGES) this.stChL_linkStates(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default Nav;
