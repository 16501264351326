// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  eventStart: ["checkbox has changed"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_checboxField_change(ARGS = { checkboxes: null, selectLimit: null }, EVENTDATA) {
  // Setup...
  const { checkboxes, selectLimit } = ARGS;
  this.logger("event", loggerMsgs.eventStart, "event", { inline: true });

  const selectedBoxes = checkboxes.filter((checkbox) => checkbox.checked);
  if (selectedBoxes.length > selectLimit) {
    const otherCheckboxes = checkboxes.filter((checkbox) => checkbox !== EVENTDATA.target);
    otherCheckboxes.forEach((box) => (box.checked = false));
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
