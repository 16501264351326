// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_mobileMenuState", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`mobile menu state:`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

// const possibleStateValues = ["foo", "bar"];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_mobileMenuState(CHANGES) {
  if (!("mobileMenuState" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { mobileMenuState } = CHANGES;
  const { mobileMenuButton, mobileMenuWrapper } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(mobileMenuState), "default", logArgs);

  // Toggle is-active attr. on mobile menu wrapper and button...
  const { is_active } = mobileMenuState;
  mobileMenuWrapper.setAttribute("data-is-active", is_active);
  mobileMenuButton.setAttribute("data-is-active", is_active);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
