// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`files:`, CHANGE],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_files(CHANGES) {
  if (!CHANGES.files) return;

  // Setup...
  const justValidateInstance = this.modules.justValidate.instance;
  const { fileNameDisplays } = this.ref;
  const { files } = CHANGES;
  this.logger("state-change", loggerMsgs.stateChange(files));

  // Updt. file name displays...
  fileNameDisplays.forEach((el) => {
    const { id } = el.dataset;
    const fileCollectionID = id.substring(6);
    const fileCollection = files[fileCollectionID];
    if (!fileCollection) return;
    if (!fileCollection.length) {
      const span = document.createElement("span");
      span.style.opacity = 0.333;
      span.innerHTML = `Keine Dateien ausgewählt…`;
      el.innerHTML = "";
      el.appendChild(span);
      return;
    }
    const fileNames = fileCollection.map((file) => file.name);
    el.innerHTML = "";
    fileNames.forEach((name) => {
      const span = document.createElement("span");
      span.innerHTML = `– ${name}`;
      el.appendChild(span);
    });

    // Validate to catch files w/ wrong extensions or too large size...
    const fieldEl = this.ref.fields.find((el) => el.dataset.id === id);
    if (!fieldEl) return;
    justValidateInstance.revalidateField(fieldEl.querySelector("input"));
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
