// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_submenuStates", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`submenu states:`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_submenuStates(CHANGES) {
  if (!("submenuStates" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { submenuStates } = CHANGES;
  const { openSubmenuBtns } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(submenuStates), "default", { ...logArgs, inline: false });

  //////////////////// Submenu state change execution ////////////////////
  ////////////////////////////////////////////////////////////////////////

  submenuStates.forEach((submenuState) => {
    // Setup...
    const { parentId, el, is_open, is_active, is_passive } = submenuState;

    // Toggle is-open attr. on submenu el...
    el.setAttribute("data-is-open", is_open);
    el.setAttribute("data-is-passsive", is_passive);

    // Toggle is-active, is-passive & has-active-child attr. on corresponding open-submenu-btn el...
    if (openSubmenuBtns == undefined) return;
    const btns = openSubmenuBtns.filter((b) => b.dataset.id === parentId);
    if (btns == undefined || btns.length === 0) return;
    btns.forEach((btn) => {
      btn.setAttribute("data-is-active", is_open);
      btn.setAttribute("data-is-passive", is_passive);
      btn.setAttribute("data-has-active-child", is_active);
    });
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
