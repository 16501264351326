// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  eventStart: ["file-field has changed"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_fileField_change(FIELDEL, EVENTDATA) {
  // Setup...
  const { files: files_old } = this.state;
  const { id } = FIELDEL.dataset;
  const fileCollectionID = id.substring(6);
  const target = EVENTDATA.target;
  const files = target.files;
  this.logger("event", loggerMsgs.eventStart, "event", { inline: true });

  // Updt. files in state...
  const fileCollection_new = [...files];
  const files_new = { ...files_old, [fileCollectionID]: fileCollection_new };
  this.setState({ files: files_new });

  // Updt. file name display data-has-file attr...
  const fileNameDisplay = FIELDEL.querySelector("[g-ref='fileNameDisplays']");
  if (fileNameDisplay) fileNameDisplay.setAttribute("data-has-file", files.length > 0);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
