// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`active item ID: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeItemID(CHANGES) {
  if (!CHANGES.activeItemID) return;

  // Setup...
  const { eventModals } = this.ref;
  const { activeItemID } = CHANGES;
  this.logger("state-change", loggerMsgs.stateChange(activeItemID), "default", { inline: true });

  // Hide/Show event modal...
  eventModals.forEach((modalEl) => {
    const { id } = modalEl.dataset;
    const is_open = id === activeItemID && activeItemID != "null";
    modalEl.setAttribute("data-is-open", is_open);
  });

  // Issue eventbus call to show page cover...
  eventbus.emit("SubeventGrid_stCh_activeItemID", { activeItemID });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
