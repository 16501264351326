// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import validate_refEL from "../../../../../app/baseUtilities/validate/validate_refEl.js";

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_submenu_mouseLeave from "../eventHandlers/eh_submenu_mouseLeave.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. submenus"],
  initSuccess: ["init. submenus", "init. success"],
  noValidDOMEl: ["init. submenus", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_submenus() {
  // Setup...
  const { submenus } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (!submenus || submenus.every((s) => !validate_refEL(s))) this.featureInit(loggerMsgs.noValidDOMEl);

  // Set init. submenu-states...
  const submenuStates = submenus.map((el) => {
    const { parentId, isActive } = el.dataset;
    const is_active = isActive === "true";
    return { el, parentId, is_active, is_visible: is_active };
  });
  this.setState({ submenuStates });

  // Event hdl...
  submenus.forEach((el) => el.addEventListener("mouseleave", eh_submenu_mouseLeave.bind(this, el)));

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
