// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_activeSlideIndex", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`active slide index: ${CHANGE}`],
  error_noStateChange: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeSlideIndex(CHANGES) {
  if (!("activeSlideIndex" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { activeSlideIndex } = CHANGES;
  const { slides } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(activeSlideIndex), "default", logArgs);

  //////////////////////// State change execution ////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // [action]...

  ////////// Slide data-is-hidden updt. //////////
  ////////////////////////////////////////////////

  slides.forEach((s, i) => s.setAttribute("data-is-hidden", i !== activeSlideIndex));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
