// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "./lib/jGia/jGia/src/eventbus";
import loadComponents from "./lib/jGia/jGia/src/loadComponents.js";

// ——————————————————————————————————————————— IMPORTS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Modules //
import JGiaAppModule from "./modules/baseAppModules/jGia";
import swupAppModule from "./modules/baseAppModules/appMod-swup";
import LazyLoaderAppModule from "./modules/baseAppModules/LazyLoader";
import WindowScroll from "./modules/WindowScroll/WindowScroll";

// Components //
import QuoteSlider from "../site/plugins/component-quote-slider/js/QuoteSlider.js";
import Modal from "../site/plugins/component-modal/js/Modal.js";
import PageCover from "../site/plugins/component-page-cover/js/PageCover.js";
import SubeventGrid from "../site/plugins/component-subevent-grid/js/SubeventGrid.js";
import EventGrid from "../site/plugins/component-event-grid/js/EventGrid.js";
import AwardRecipientList from "../site/plugins/component-award-recipient-list/js/AwardRecipientList.js";
import Slideshow from "../site/plugins/component-slideshow/js/Slideshow.js";
// import PDFViewer from "../site/plugins/component-p-d-f-viewer/js/PDFViewer.js"; // ← not in use, accusoft reader inits from markup
import CardGrid from "../site/plugins/component-card-grid/js/CardGrid.js";
import CardGridFilterBar from "../site/plugins/component-card-grid-filter-bar/js/CardGridFilterBar.js";
import Nav from "../site/plugins/component-nav/js/Nav.js";
import Nav_PROTO from "../site/plugins/component-nav-proto/js/Nav-proto.js";
import GrantApplicationForm from "../site/plugins/component-grant-application-form/js/GrantApplicationForm.js";
import ScholarshipApplicationForm from "../site/plugins/component-scholarship-application-form/js/ScholarshipApplicationForm.js";
import EventSignUpForm from "../site/plugins/component-event-sign-up-form/js/EventSignUpForm.js";
import NewsletterSignUpForm from "../site/plugins/component-newsletter-sign-up-form/js/NewsletterSignUpForm.js";

const availableComponents = [
  // Global
  // ...

  // Local
  { type: "QuoteSlider", script: QuoteSlider },
  { type: "Nav_PROTO", script: Nav_PROTO },
  { type: "Modal", script: Modal },
  { type: "PageCover", script: PageCover },
  { type: "SubeventGrid", script: SubeventGrid },
  { type: "EventGrid", script: EventGrid },
  { type: "AwardRecipientList", script: AwardRecipientList },
  { type: "Slideshow", script: Slideshow },
  // { type: "PDFViewer", script: PDFViewer }, // ← not in use, accusoft reader inits from markup
  { type: "Nav", script: Nav },
  { type: "CardGrid", script: CardGrid },
  { type: "CardGridFilterBar", script: CardGridFilterBar },
  { type: "GrantApplicationForm", script: GrantApplicationForm },
  { type: "ScholarshipApplicationForm", script: ScholarshipApplicationForm },
  { type: "EventSignUpForm", script: EventSignUpForm },
  { type: "NewsletterSignUpForm", script: NewsletterSignUpForm },

  // Other
  // ...
];

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import set_imgCaptionWidths from "./util/set/set_imgCaptionWidths.js";

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_swup_page_load from "./eventHandlers/swup/eh_swup_page_load";
import eh_swup_visit_end from "./eventHandlers/swup/eh_swup_visit_end.js";

// Custom event handlers for swup module //
const swupEventHandlers = [
  { id: "page:load", handler: eh_swup_page_load },
  { id: "visit:end", handler: eh_swup_visit_end },
];

// Custom eventbus handlers for swup module //
const swupEventbusConfig = {
  callsToAnswer: ["document_click"],
  handlers: [{ id: "document_click", handler: () => console.log("swup: document was clicked") }],
};

// ————————————————————————————————————————— MODULE INIT. ————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

(async () => {
  // Fetch app config...
  const appConfigRequest = await fetch("/app/config.json");
  const appConfig = await appConfigRequest.json();
  const giaConfig = appConfig?.gia;
  const swupConfig = appConfig?.swup;
  const lazyLoaderConfig = appConfig?.lazyLoader;
  const globalComponentConfigs = appConfig?.globalComponents;
  const localComponentConfigs = appConfig?.localComponents;
  const windowScrollConfig = appConfig?.windowScroll;

  // Store required configs in localStorage...
  const navData = appConfig?.navigation;
  const navDataString = navData ? JSON.stringify(navData) : null;
  if (navDataString) localStorage.setItem("navData", navDataString);

  // Extend module configs...
  swupConfig.eventbus = swupEventbusConfig;
  swupConfig.eventHandlers = swupEventHandlers;
  swupConfig.useNewJGiaPlugin = true;

  // Create & collect module app module instances...
  const jGiaAppModuleInstance = new JGiaAppModule(giaConfig, globalComponentConfigs, { availableComponents });
  const swupAppModuleInstance = new swupAppModule(swupConfig, giaConfig, { availableComponents }, localComponentConfigs);
  const LazyLoaderAppModuleInstance = new LazyLoaderAppModule(lazyLoaderConfig);
  const WindowScrollInstance = new WindowScroll(windowScrollConfig);
  const appModules = [jGiaAppModuleInstance, swupAppModuleInstance, LazyLoaderAppModuleInstance, WindowScrollInstance];

  // Include custom modules...
  // if (fooConfig) appModules.push(new Foo(fooConfig));

  // Init. app modules...
  try {
    console.log("%cInitialising app modules", "color: orange; font-weight: bold;");
    appModules.forEach((module) => {
      try {
        module.init();
      } catch (error) {
        console.error(`Module "${module.options.name}" failed to initialise.`);
        throw error;
      }
    });
    console.log("%cApp modules initialised", "color: green; font-weight: bold;");
  } catch (error) {
    console.error(error);
  }

  // Manually init. Gia comp. inside modals...
  const modals = document.querySelectorAll(".Modal");
  modals.forEach((modalEl) => {
    loadComponents({ NewsletterSignUpForm }, modalEl, null, localComponentConfigs);
  });
})();

// ————————————————————————————————————————— GLOBAL STATE ————————————————————————————————————————— //
// ——————————————————————————————————————— (local storage ) ——————————————————————————————————————— //

// Create/Set global state object //
console.log("%cSetting global state", "color: orange; font-weight: bold;");
const globalState = { mediaIsEnabled: true };
localStorage.setItem("globalState", JSON.stringify(globalState));

// ———————————————————————————————————————— GLOBAL EVENTS ————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Redirect if current route ends in '/karte'...
if (window.location.pathname.endsWith("/karte")) {
  const redirectTargetHolder = document.querySelector("[data-ref='redirectTargetHolder']");
  const redirectTarget = redirectTargetHolder ? redirectTargetHolder.dataset.redirectTarget : "/home";
  window.location.replace(window.location.pathname.replace("/karte", redirectTarget));
}

// Set img. caption widths on first page load...
set_imgCaptionWidths();

// import ebh_Foo_bar from "./eventbusHandlers/ebh_Foo_bar";

// Event emissions //
window.addEventListener("resize", () => {
  // Emit window resize event...
  eventbus.emit("window_resize", { windowWidth: window.innerWidth });
  // Set img. caption widths on window resize...
  set_imgCaptionWidths();
});
window.addEventListener("scroll", () => eventbus.emit("window_scroll", { scrollPos: window.scrollY }));
document.documentElement.addEventListener("mouseleave", () => eventbus.emit("document_mouseLeave"));
document.documentElement.addEventListener("click" || "touchstart", (e) => eventbus.emit("document_click", { eventData: e }));

// Eventbus handling //
// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
