// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger";
import validate_refEl from "../../../../app/baseUtilities/validate/validate_refEl.js";
import cancel_featureInit from "../../../../app/baseUtilities/cancel/cancel_featureInit";
import cancel_ebh from "../../../../app/baseUtilities/cancel/cancel_ebh.js";
import reset_form from "./util/reset_form.js";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_justValidate from "./init/init_justValidate.js";
import init_navButtons from "./init/init_navButtons.js";
import init_fileFields from "./init/init_fileFields.js";
import init_textareaFields from "./init/init_textareaFields.js";
import init_selectFields from "./init/init_selectFields.js";
import init_submitButton from "./init/init_submitButton.js";
import init_pageButtons from "./init/init_pageButtons.js";

// ——————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS/API ——————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_window_resize from "./eventbusHandlers/ebh_window_resize.js";

import stChH_mode from "./stateChangeHandlers/stChH_mode.js";
import stChH_status from "./stateChangeHandlers/stChH_status.js";
import stChH_currentPage from "./stateChangeHandlers/stChH_currentPage.js";
import stChH_files from "./stateChangeHandlers/stChH_files.js";
import stChH_notifStates from "./stateChangeHandlers/stChH_notifStates.js";
import stChH_submitAttempts from "./stateChangeHandlers/stChH_submitAttempts.js";
import stChH_uploadProgress from "./stateChangeHandlers/stChH_uploadProgress.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Options with case-sensitive keys that are not to be
// automatically extracted from the options arg. but be
// assigned to an option key manually to preserve its case
// (Kirby CMS converts all fields/option keys to lowercase)
// (see constructor).

const manualOptionKeys = ["optionkey"];

//  Default values for manually extracted options
//  (see constructor, in case specific option has not been provided
//  in comp. config.).

const defaultOptions = {
  optionkey: { foo: "bar" },
};

// Default log styles

const defaultLogStyles = {
  default: "#6682d6",
  action: "#c7d0ff",
  event: "#97a5ce",
  warning: "#ffaf00",
  error: "#ff3232",
  success: "#00c853",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class ScholarshipApplicationForm extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element, options) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {
      navButton_prev: null,
      navButton_next: null,
      submitButton: null,
      formBody: null,
      formPages: [],
      fields: [],
      fieldGroups: [],
      statusIndicators_valid: [],
      fileNameDisplays: [],
      notification_invalidField: null,
      notification_submissionSuccess: null,
      notification_gmail: null,
      pageButtons: [],
      uploadProgressBar: null,
    };

    //////////// Options /////////////
    //////////////////////////////////

    // Get options not to be manually extracted from the options arg...
    const autoOptions = {};
    for (const key in options) if (!manualOptionKeys.includes(key)) autoOptions[key] = options[key];

    this.options = {
      name: "ScholarshipApplicationForm",
      version: element.getAttribute("g-version") ?? "1",
      // optionKey: options.optionkey,
      ...autoOptions,
      //////
      // run_withLogs: false,
      // logStyles: defaultLogStyles,
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);
    this.validate_refEl = validate_refEl.bind(this);
    this.cancel_featInit = cancel_featureInit.bind(this);
    this.cancel_ebh = cancel_ebh.bind(this);
    this.reset_form = reset_form.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    this.init_selectFields = init_selectFields.bind(this);
    this.init_textareaFields = init_textareaFields.bind(this);
    this.init_fileFields = init_fileFields.bind(this);
    this.init_pageButtons = init_pageButtons.bind(this);
    this.init_submitButton = init_submitButton.bind(this);
    this.init_navButtons = init_navButtons.bind(this);
    this.init_justValidate = init_justValidate.bind(this);

    ///////////// Modules //////////////
    ////////////////////////////////////

    this.modules = {
      justValidate: { instance: this.init_justValidate() },
    };

    /////////////// API ////////////////
    ////////////////////////////////////

    this.api = {
      hideComp: () => this.setState({ hidden: true }),
      showComp: () => this.setState({ hidden: false }),
    };

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_window_resize = ebh_window_resize.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_uploadProgress = stChH_uploadProgress.bind(this);
    this.stChL_submitAttempts = stChH_submitAttempts.bind(this);
    this.stChL_notifStates = stChH_notifStates.bind(this);
    this.stChL_files = stChH_files.bind(this);
    this.stChL_currentPage = stChH_currentPage.bind(this);
    this.stChL_status = stChH_status.bind(this);
    this.stChL_mode = stChH_mode.bind(this);

    ////// Custom event handlers ///////
    // (To be passed to parent class) //

    // ...

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    this.logger("info", ["mounting"], "action", { eventName: "mount", inline: true });
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    this.logger("info", ["unmounting"], "action", { eventName: "unmount", inline: true });

    /////////////////////////////
    // Listener deregistration //
    /////////////////////////////

    eventbus.off("window_resize", this.ebl_window_resize);

    /////////////////////////////
    // API call deregistration //
    /////////////////////////////

    eventbus.off("ScholarshipApplicationForm.api.hideComp", this.api.hideComp);
    eventbus.off("ScholarshipApplicationForm.api.showComp", this.api.showComp);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.setState({ mode: "init" });
    this.init_fileFields();
    this.init_selectFields();
    this.init_textareaFields();
    this.init_pageButtons();
    this.init_submitButton();
    this.init_navButtons();
    this.init_states();
    this.init_eventbus();
    this.setState({ mode: "ready" });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    this.logger("init", ["states"], "action", { eventName: "init_states", inline: true });
    this.setState({
      status: "invalid",
      currentPage: 1,
      submitAttempts: 0,
      files: { additionalDocuments: [] },
      notifStates: {
        invalidField: { is_active: false },
        submissionSuccess: { is_active: false },
        gmail: { is_active: false },
      },
      is_mobile: window.innerWidth < 640,
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { eventName: "init_eventbus", inline: true });

    ///////////////////////////
    // Listener registration //
    ///////////////////////////

    eventbus.on("window_resize", this.ebl_window_resize);

    ///////////////////////////
    // API call registration //
    ///////////////////////////

    eventbus.on("ScholarshipApplicationForm.api.hideComp", this.api.hideComp);
    eventbus.on("ScholarshipApplicationForm.api.showComp", this.api.showComp);
  }

  /////////////////////////// State management ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    if ("uploadProgress" in CHANGES) this.stChL_uploadProgress(CHANGES);

    if ("submitAttempts" in CHANGES) this.stChL_submitAttempts(CHANGES);

    if ("notifStates" in CHANGES) this.stChL_notifStates(CHANGES);

    if ("files" in CHANGES) this.stChL_files(CHANGES);

    if ("currentPage" in CHANGES) this.stChL_currentPage(CHANGES);

    if ("status" in CHANGES) this.stChL_status(CHANGES);

    if ("mode" in CHANGES) this.stChL_mode(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default ScholarshipApplicationForm;
