// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`new page title: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_pageTitle(CHANGES) {
  // Setup...
  const { pageTitleTextEl, mobilePageTitleTextEl } = this.ref;
  const { pageTitle } = CHANGES;
  this.logger("state-change", loggerMsgs.stateChange(pageTitle), "default", { inline: true });

  // Updt. page title text el...
  pageTitleTextEl.innerHTML = pageTitle;
  mobilePageTitleTextEl.innerHTML = pageTitle;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
