// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_fileField_change from "../eventHandlers/eh_fileField_change.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. file-fields"],
  initSuccess: ["init. file-fields", "init. success"],
  noValidDOMEl: ["init. file-fields", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_fileFields() {
  // Setup...
  const { fields } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (fields.every((el) => !this.validate_refEL(el))) return this.cancel_featureInit(loggerMsgs.noValidDOMEl);

  // Listen to change events on file fields...
  const fileFields = fields.filter((el) => el.dataset.type === "file");
  if (fileFields.length) fileFields.forEach((el) => el.addEventListener("change", eh_fileField_change.bind(this, el)));

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
