// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_navButton_click from "../eventHandlers/eh_navButton_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. nav buttons"],
  initSuccess: ["init. nav buttons", "init. success"],
  noValidDOMEl: ["init. nav buttons", "no valid DOM elements provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_navButtons() {
  // Setup...
  const { navButton_prev, navButton_next } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (!this.validate_refEL(navButton_prev) && !this.validate_refEL(navButton_next))
    return this.cancel_navButtonsInit(loggerMsgs.noValidDOMEl);

  // Listen to click events on nav buttons...
  if (this.validate_refEL(navButton_prev))
    navButton_prev.addEventListener("click", eh_navButton_click.bind(this, navButton_prev));
  if (this.validate_refEL(navButton_next))
    navButton_next.addEventListener("click", eh_navButton_click.bind(this, navButton_next));

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
