// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_uploadProgress", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`upload progress: ${CHANGE}`],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_uploadProgress(CHANGES) {
  if (!("uploadProgress" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { uploadProgress } = CHANGES;
  const { uploadProgressBar } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(uploadProgress), "default", logArgs);

  // Show/hide progress bar...
  uploadProgressBar.setAttribute("data-is-hidden", uploadProgress === 0 || uploadProgress === 100);

  // Updt. progress bar...
  uploadProgressBar.style.setProperty("--progress", `${uploadProgress}%`);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
