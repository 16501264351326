// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_searchBarState", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`search bar state:`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

// const possibleStateValues = ["foo", "bar"];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_searchBarState(CHANGES) {
  if (!("searchBarState" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { searchBarState } = CHANGES;
  const { searchBarButton, searchBarInputWrapper } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(searchBarState), "default", { ...logArgs, inline: false });

  // Set search bar button/input hidden/active attr...
  const { is_active } = searchBarState;
  searchBarButton.setAttribute("data-is-hidden", is_active);
  searchBarInputWrapper.setAttribute("data-is-active", is_active);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
