// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_textareaField_input from "../eventHandlers/eh_textareaField_input.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_textareaFields", inline: true };
const loggerMsgs = {
  initStart: ["init. textareaFields"],
  initSuccess: ["init. textareaFields", "init. success"],
  noValidDOMEl: ["init. textareaFields", "no valid DOM element(s) provided"],
  noTextareaFields: ["init. textarea-fields", "no textarea-fields found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_textareaFields() {
  // Setup...
  const { fields } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (fields.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Listen to change events on textarea fields...
  const textareaFields = fields.filter((el) => el.dataset.type === "textarea");
  if (!textareaFields.length) return this.cancel_featInit(loggerMsgs.noTextareaFields);
  textareaFields.forEach((el) => el.addEventListener("input", eh_textareaField_input.bind(this, el)));

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
