// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_openSubmenuBtn_click", inline: true };
const loggerMsgs = {
  eventStart: ["open-submenu-btn was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_openSubmenuBtn_click(BTNEL) {
  // Setup...
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Open/Close corresp. submenu (submenus-state updt.)...
  const { submenuStates } = this.state;
  const submenuID = BTNEL.dataset.id;
  const submenuStates_new = submenuStates.map((s) => ({
    ...s,
    is_open: (s.parentId === submenuID && !s.is_open) || s.is_active,
  }));
  this.setState({ submenuStates: submenuStates_new });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
