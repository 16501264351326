// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_navButton_click", inline: true };
const loggerMsgs = {
  eventStart: ["nav-button was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_navButton_click(BUTTONEL) {
  // Setup...
  const { currentPage } = this.state;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Go to prev./next page...
  const maxPage = 3;
  const direction = BUTTONEL.dataset.direction;
  const currentPage_new = direction === "prev" ? currentPage - 1 : currentPage + 1;
  if (currentPage_new < 1 || currentPage_new > maxPage) return;
  this.setState({ currentPage: currentPage_new });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
