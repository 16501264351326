// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_hidden", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`is hidden: ${CHANGE}`],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

const possibleStateValues = [true, false];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_hidden(CHANGES) {
  if (!("hidden" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);
  if (!possibleStateValues.includes(CHANGES.change))
    return this.logger("error", loggerMsgs.error_invalidStateValue(CHANGES.change), "error", logArgs);

  // Setup...
  const { hidden } = CHANGES;
  this.logger("state-change", loggerMsgs.stateChange(hidden), "default", logArgs);

  // Hide/Show comp. el...
  this.element.setAttribute("data-is-hidden", hidden);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
