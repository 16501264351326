// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  noValidDOMEl: ["hdl. document-click eventbus call", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_document_click(ARGS) {
  // Setup...
  const { searchBar } = this.ref;
  const { eventData, _name } = ARGS;
  const { target } = eventData;
  this.logger("eventbus call", [_name, ARGS], "event");

  // Guard...
  if (!this.validate_refEL(searchBar)) return this.cancel_ebh(loggerMsgs.noValidDOMEl);

  // Close search bar, is click occurred outside of it //
  if (!searchBar.contains(target)) this.setState({ searchBarState: { is_active: false } });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
