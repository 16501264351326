// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_mobileMenuButton_click", inline: true };
const loggerMsgs = {
  eventStart: ["mobile-menu-button was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_mobileMenuButton_click() {
  // Setup...
  const { mobileMenuState } = this.state;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Toggle mobile menu is-active state...
  const has_oldState = mobileMenuState !== null && typeof mobileMenuState === "object";
  const mobileMenuState_new = has_oldState ? { ...mobileMenuState, is_active: !mobileMenuState.is_active } : { is_active: true };
  this.setState({ mobileMenuState: mobileMenuState_new });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
