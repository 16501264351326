// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function validate_linkStates(LINKSTATES) {
  return !LINKSTATES || !LINKSTATES.length || LINKSTATES.constructor !== Array;
}

function validate_currentRouteID(CURRENTROUTEID) {
  return !CURRENTROUTEID || CURRENTROUTEID.length < 1 || CURRENTROUTEID.constructor !== String;
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.make_updtLinkStates", inline: true };
const loggerMsgs = {
  eventStart: ["Nav-proto", "API", "make_updtLinkStates"],
  noValidLinkStates: ["make_updtLinkStates()", "no valid linkStates provided"],
  noCurrentRouteID: ["make_updtLinkStates()", "no currentRouteID provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function make_updtLinkStates(LINKSTATES_old, CURRENTROUTEID) {
  // Setup...
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Guard...
  if (validate_linkStates(LINKSTATES_old)) return this.logger("error", loggerMsgs.noValidLinkStates, "error", { inline: true });
  if (validate_currentRouteID(CURRENTROUTEID))
    return this.logger("error", loggerMsgs.noCurrentRouteID, "error", { inline: true });

  // Create updated link-states...
  const activeLink = LINKSTATES_old.find((linkState) => linkState.id === CURRENTROUTEID);
  const activeLinkParentId = activeLink ? activeLink.parentId : null;
  const currentRouteRoot = CURRENTROUTEID === "home" ? "home" : CURRENTROUTEID.split("/")[0];
  const linkStates_new = LINKSTATES_old.map((lS) => {
    const { target, id } = lS;
    const is_active = id === CURRENTROUTEID || target === CURRENTROUTEID || id === activeLinkParentId || id === currentRouteRoot;
    return { ...lS, is_active };
  });
  return linkStates_new;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
