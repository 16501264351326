// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_swup_page_load(ARGS) {
  // Setup...
  const { linkStates } = this.state;
  const { fragmIsBeingOpened, _name } = ARGS;
  this.logger("eventbus call", [_name], "event", { inline: true });

  // Block window scroll when nav. to fragm. page...
  this.setState({ mode: fragmIsBeingOpened ? "scrollDisabled" : "scrollEnabled" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
