var get_elementStyleValue = function (e, style) {
  var styleValue = "";
  //////
  if (document.defaultView && document.defaultView.getComputedStyle) {
    styleValue = document.defaultView.getComputedStyle(e, "").getPropertyValue(style);
  } else if (e.currentStyle) {
    style = style.replace(/\-(\w)/g, function (strMatch, p1) {
      return p1.toUpperCase();
    });
    //////
    styleValue = e.currentStyle[style];
  }
  //////
  return styleValue;
};

export default get_elementStyleValue;
