// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMessages = {
  stateChange: (CHANGE) => [`search-bar-state:`, CHANGE],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_searchBarState(CHANGES) {
  // Setup...
  const { searchBarButton, searchBarInputWrapper } = this.ref;
  const { searchBarState } = CHANGES;
  this.logger("state-change", loggerMessages.stateChange(searchBarState));

  // Set search bar button/input hidden/active attr...
  const { is_active } = searchBarState;
  searchBarButton.setAttribute("data-is-hidden", is_active);
  searchBarInputWrapper.setAttribute("data-is-active", is_active);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
