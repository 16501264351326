// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "ebh_swup_page_load" };
const loggerMsgs = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_swup_page_load(ARGS) {
  // Setup...
  const { linkData } = this.options;
  const { to: swupTrans_to, _name } = ARGS;
  const { url: targetURL } = swupTrans_to;
  const { linkStates, mobileMenuState } = this.state;
  this.logger("eventbus call", [_name, ARGS], "event", logArgs);

  // Create updt. link states w/ active link corresp. to loaded route...
  const target_isRoot = swupTrans_to.url === "/" || swupTrans_to.url.startsWith("/page:");
  const currentRouteID = target_isRoot ? "home" : swupTrans_to.url.slice(1);
  const linkStates_new = this.api.make_updtLinkStates(linkStates, currentRouteID);

  // Close mobile menu (is_active in mobile menu state)...
  const mobileMenuState_new = { ...mobileMenuState, is_active: false };

  // Updt. page title text...
  const subLinks = linkData.map((l) => l.subLinks).flat();
  let linkData_target = linkData.find((l) => l.id === currentRouteID);
  if (!linkData_target) linkData_target = subLinks.find((l) => l.id === currentRouteID);
  const { title: targetLinkTitle, text: targetLinkText } = linkData_target ?? {};
  let pageTitle = targetURL.startsWith("/home/")
    ? "Beitrag"
    : targetURL.startsWith("/suche")
    ? "Suchergebnisse"
    : targetURL.startsWith("/preise")
    ? "Preise"
    : targetURL.startsWith("/die-stiftung")
    ? "Die Stiftung"
    : targetLinkTitle ?? targetLinkText ?? "";

  // Updt. states...
  this.setState({
    linkStates: linkStates_new,
    // mobileMenuState: mobileMenuState_new,
    pageTitle,
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
