// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import validate_refEL from "../../../baseUtilities/validate/validate_refEl";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function scrollTo_target(TARGET) {
  if (!validate_refEL(TARGET)) return;

  // Setup...
  const { instance: sweetScrollInstance } = this.modules.SweetScroll;

  // Determine target position (scroll top of target to ~ center of viewport)...
  const targetPosition = TARGET.offsetTop - (window.innerHeight / 2) * 0.8;

  // Scroll to target...
  sweetScrollInstance.to(targetPosition, { easing: "easeOutExpo", duration: 800 });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
