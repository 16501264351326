// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import validate_refEL from "../../../../../app/baseUtilities/validate/validate_refEl.js";

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_link_mouseEnter from "../eventHandlers/eh_link_mouseEnter.js";
import eh_link_mouseLeave from "../eventHandlers/eh_link_mouseLeave.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. links"],
  initSuccess: ["init. links", "init. success"],
  noValidDOMEl: ["init. links", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_links() {
  // Setup...
  const { links } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (!links || links.every((l) => !validate_refEL(l))) this.featureInit(loggerMsgs.noValidDOMEl);

  // Set init. link-states...
  const linkStates = links.map((el) => {
    const target = el.href.split("/").pop();
    const { id: id_raw, parentId, isActive, isParent } = el.dataset;
    const id = id_raw.startsWith("/") ? id_raw.slice(1) : id_raw; // ← remove leading slash from ID
    const alias = el.dataset.alias != "" ? el.dataset.alias : null;
    const is_active = isActive === "true";
    const is_parent = isParent === "true";
    return { el, target, id, parentId, alias, is_active, is_parent };
  });
  this.setState({ linkStates });

  // Event hdl...
  links.forEach((el) => {
    el.addEventListener("mouseenter", eh_link_mouseEnter.bind(this, el));
    // el.addEventListener("mouseleave", eh_link_mouseLeave.bind(this, el));
  });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
