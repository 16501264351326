// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_eventModalCloseButton_click from "../eventHandlers/eh_eventModalCloseButton_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. eventModals"],
  initSuccess: ["init. eventModals", "init. success"],
  noValidDOMEl: ["init. eventModals", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_eventModals() {
  // Setup...
  const { eventModals } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (eventModals.every((el) => !this.validate_refEL(el))) return this.cancel_featureInit(loggerMsgs.noValidDOMEl);

  // Listen to click-events on event modal close buttons...
  eventModals.forEach((modalEl) => {
    const closeButton = modalEl.querySelector("[data-ref=closeButton]");
    closeButton.addEventListener("click", eh_eventModalCloseButton_click.bind(this));
  });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
