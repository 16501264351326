// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`notifications-states:`, CHANGE],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_notificationsStates(CHANGES) {
  if (!CHANGES.notificationsStates) return;

  // Setup...
  const { notification_invalidField, notification_submissionSuccess, notification_gmail } = this.ref;
  const { notificationsStates } = CHANGES;
  this.logger("state-change", loggerMsgs.stateChange(notificationsStates));

  // Hide/show notifications...
  notification_invalidField.setAttribute("data-is-active", notificationsStates.invalidField.is_active);
  notification_submissionSuccess.setAttribute("data-is-active", notificationsStates.submissionSuccess.is_active);
  notification_gmail.setAttribute("data-is-active", notificationsStates.gmail.is_active);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
