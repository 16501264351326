// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_checboxField_change from "../eventHandlers/eh_checboxField_change.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. checkbox-field-groups"],
  initSuccess: ["init. checkbox-field-groups", "init. success"],
  noValidDOMEl: ["init. checkbox-field-groups", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_checkboxFieldGroups() {
  // Setup...
  const { fieldGroups } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (fieldGroups.every((el) => !this.validate_refEL(el))) return this.cancel_featureInit(loggerMsgs.noValidDOMEl);

  // Get checkbox field groups...
  const checkboxFieldGroups = fieldGroups.filter((el) => el.dataset.type === "checkboxes");
  if (!checkboxFieldGroups.length) return;

  // Listen to changes to checbox fields in group...
  checkboxFieldGroups.forEach((group) => {
    // Setup...
    const checkboxes = [...group.querySelectorAll("input[type='checkbox']")];
    const selectLimit = parseInt(group.dataset.selectLimit);

    // If group has select-limit, listen to change events on checkboxes
    // in order to deselect other checkboxes when limit is reached...
    if (selectLimit) {
      checkboxes.forEach((box) => {
        const args = { checkboxes, selectLimit };
        box.addEventListener("change", eh_checboxField_change.bind(this, args));
      });
    }
  });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
