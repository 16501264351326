// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMessages = {
  stateChange: (CHANGE) => [`submenu-states:`, CHANGE],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_submenuStates(CHANGES) {
  if (!CHANGES.submenuStates) return;

  // Setup...
  const { submenuStates } = CHANGES;
  this.logger("state-change", loggerMessages.stateChange(submenuStates));

  // Toggle active attr. on submenu elements...
  submenuStates.forEach((submenuState) => {
    const { el, is_active, is_visible } = submenuState;
    el.setAttribute("data-is-active", is_active);
    el.setAttribute("data-is-visible", is_visible);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
