// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_window_scroll(ARGS) {
  // Setup...
  const { version } = this.options;
  const { scrollPos, _name } = ARGS;
  this.logger("eventbus call", [_name, ARGS], "event");

  // Hide/show page title and search bar on scroll (v1)...
  if (version == 1) this.setState({ pageTitleHidden: scrollPos > 100, searchBarHidden: scrollPos > 100 });

  // Hide/show Nav on scroll (v2)...
  if (version == 2) this.setState({ hidden: scrollPos > 100 });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
