// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMessages = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_scrollPosition(CHANGES) {
  // Setup...
  const { maxScrollPosition } = this.state;
  const { scrollPosition } = CHANGES;
  this.logger("state-change", [`scroll position: ${scrollPosition}`], "default", { inline: true });

  // Store scroll pos. in CSS var. on doc. el...
  document.documentElement.style.setProperty("--scroll-y", `${scrollPosition}px`);

  // Emit global eventbus call...
  const args = {
    scrollPosition,
    scrollPercentage: (scrollPosition / maxScrollPosition) * 100,
  };
  eventbus.emit("windowScroll_stCh_scrollPosition", args);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
