// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_link_mouseEnter", inline: true };
const loggerMsgs = {
  eventStart: ["mouse entered link"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_link_mouseEnter(LINKEL, EVENTDATA) {
  // Setup...
  const linkID = LINKEL.dataset.id;
  const is_parent = LINKEL.dataset.isParent === "true";
  const is_child = LINKEL.dataset.isChild === "true";
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Stop, if link is child...
  if (is_child) {
    console.warn("eh_link_mouseEnter(): link is a child, no submenu to open, stopping");
    return;
  }

  // If link is not parent, hide all submenus...
  if (!is_parent) {
    console.warn("eh_link_mouseEnter(): link is not a parent, no submenu to open, closing all submenus");
    const submenuStates_new = this.state.submenuStates.map((ss) => ({ ...ss, is_visible: false }));
    this.setState({ submenuStates: submenuStates_new });
    return;
  }

  // Show submenu corresponding to link...
  const { submenuStates } = this.state;
  const submenuStates_new = submenuStates.map((s) => ({ ...s, is_visible: s.parentId === linkID }));
  this.setState({ submenuStates: submenuStates_new });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
