// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_confirmOptField_change", inline: true, disabled: false };
const msgs = {
  start: ["confirm option field changed"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_confirmOptField_change(EVENTDATA) {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const compEl = this.element;
  const { formField_withCompany, withCompanyFieldWrapper, formField_firstName_company, formField_lastName_company } = this.ref;
  const { target: clickedFieldEl } = EVENTDATA;
  const { with_companySignUpOption } = this.options;
  this.logger("event", msgs.start, "event", logArgs);

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Uncheck all other confirm. option fields...
  const confirmOptFields = compEl.querySelectorAll("[g-ref*='formField_confirmOption']");
  confirmOptFields.forEach((fieldEl) => (fieldEl.checked = fieldEl === clickedFieldEl));

  if (with_companySignUpOption) {
    // Uncheck with-company checkbox...
    formField_withCompany.checked = false;

    // Set form field group related to accompanying person inactive...
    withCompanyFieldWrapper.setAttribute("data-is-disabled", true);

    // Set first & last name fields of accompanying person not required...
    formField_firstName_company.required = false;
    formField_lastName_company.required = false;
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
