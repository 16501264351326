// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  eventStart: ["slide was changed"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_slideChange() {
  // Setup...
  const { slides } = this.ref;
  const flickityInstance = this.modules.flickity.instance;
  this.logger("event", loggerMsgs.eventStart, "event", { inline: true });

  // Updt. selected-slide-index & caption state...
  const selectedSlideIndex = flickityInstance.selectedIndex + 1;
  const selectedSlideCaption = slides[selectedSlideIndex - 1].dataset.caption;
  this.setState({ selectedSlideIndex, caption: selectedSlideCaption });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
