// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function reset_form() {
  // Setup...
  const { fields } = this.ref;

  // Reset fields...
  fields.forEach((f) => {
    const { type } = f.dataset;

    // Reset text fields...
    if (type === "text" || type === "textarea" || type === "email" || type === "phone" || type === "number") {
      const inputEl = f.querySelector("input") || f.querySelector("textarea");
      if (inputEl) inputEl.value = "";
    }

    // Reset checkbox/select fields...
    if (type === "checkbox" || type === "select") {
      const inputEls = f.querySelectorAll("input");
      if (inputEls) inputEls.forEach((el) => (el.checked = false));
    }

    // Reset file fields...
    if (type === "file") {
      const inputEl = f.querySelector("input");
      if (inputEl) inputEl.value = null;

      // Reset files state...
      this.setState({ files: { coverLetter: [], expose: [], textSample: [], workSample: [] } });
    }
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
