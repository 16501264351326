// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`mobile menu state:`, CHANGE],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_mobileMenuState(CHANGES) {
  if (!("mobileMenuState" in CHANGES)) return;

  // Setup...
  const { mobileMenuButton, mobileMenuWrapper } = this.ref;
  const { mobileMenuState } = CHANGES;
  this.logger("state-change", loggerMsgs.stateChange(mobileMenuState));

  // Toggle is-active attr. on mobile menu wrapper and button...
  const { is_active } = mobileMenuState;
  mobileMenuWrapper.setAttribute("data-is-active", is_active);
  mobileMenuButton.setAttribute("data-is-active", is_active);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
