// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_openSubmenuBtn_click from "../eventHandlers/eh_openSubmenuBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_submenus", inline: true };
const loggerMsgs = {
  initStart: ["init. submenus"],
  initSuccess: ["init. submenus", "init. success"],
  noValidDOMEl: ["init. submenus", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_submenus() {
  // Setup...
  const { submenus, openSubmenuBtns } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (submenus === undefined) return this.cancel_featInit(loggerMsgs.noValidDOMEl);
  if (submenus.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Event hdl...
  if (openSubmenuBtns !== undefined && openSubmenuBtns.every((el) => this.validate_refEl(el))) {
    openSubmenuBtns.forEach((btn) => btn.addEventListener("click", eh_openSubmenuBtn_click.bind(this, btn)));
  }

  // Set init. submenu-states...
  const submenuStates = submenus.map((el) => {
    const { parentId, isOpen } = el.dataset;
    const is_open = isOpen === "true";
    return { el, parentId, is_open };
  });
  this.setState({ submenuStates });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
