// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import JustValidate from "just-validate";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. justValidate instance"],
  initSuccess: ["init. justValidate instance", "init. success"],
  noValidDOMEl: ["init. justValidate instance", "no valid DOM element provided"],
  validating: ["validating form", "updt. status"],
};

const errorMsgs = {
  required: "↑ Pflichtfeld",
  email: "↑ Bitte gültige E-Mail-Adresse eingeben",
  phone: "↑ Bitte gültige Telefonnummer eingeben",
  fieldGroup: "↑ Bitte eine Option wählen",
  maxLength: "↑ Max. Zeichenanzahl überschritten",
  wrongSum: "↑ Summe stimmt nicht",
};

const validationRules = {
  files: [
    {
      rule: "files",
      value: { files: { extensions: ["pdf"], maxSize: 25000000, types: ["application/pdf"] } },
      errorMessage: "Bitte nur PDF-Dateien mit einer max. Größe von 25mb auswählen!",
    },
  ],
};

const regex_phoneNumber = /^[0-9+ ]+$/;

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_justValidate() {
  // Setup...
  const { formBody, fields, fieldGroups } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (!this.validate_refEL(formBody)) return this.cancel_featureInit(loggerMsgs.noValidDOMEl);

  // Create justValidate instance...
  const justValidateInstance = new JustValidate(formBody, {
    submitFormAutomatically: false,
    focusInvalidField: false,
  });

  // Guard...
  if (fields.forEach((f) => !this.validate_refEL(f))) return this.cancel_featureInit(loggerMsgs.noValidDOMEl);

  // Add fields to instance...
  fields.forEach((f) => {
    // Guard...
    const inputEl = f.querySelector("input") || f.querySelector("textarea");
    if (!this.validate_refEL(inputEl)) return;

    // Setup...
    const { type, isRequired } = f.dataset;
    const is_required = isRequired === "true";

    if (type != "select" && type != "file" && type != "number") {
      //         ^^^^^^              ^^^^              ^^^^^^
      //         ↑                   ↑ File- & number-fields are handled separately
      //         ↑ Select-fields are handled as field groups (select fields are groups of checkboxes)

      // Compose field rules...
      const { maxLength: maxLengthString, type } = f.dataset;
      const maxLength = parseInt(maxLengthString);
      const is_email = type === "email";
      const is_phone = type === "phone";
      const rules = [{ rule: "maxLength", value: maxLength, errorMessage: errorMsgs.maxLength }];

      // Add rules for email/phone validation...
      if (is_email) rules.push({ rule: "email", errorMessage: errorMsgs.email });
      if (is_phone) rules.push({ validator: (val) => val === "" || regex_phoneNumber.test(val), errorMessage: errorMsgs.phone });

      // Add rule if required...
      if (is_required) rules.push({ rule: "required", errorMessage: errorMsgs.required });

      // Add field...
      justValidateInstance.addField(inputEl, rules);
    } else if (type === "select") {
      // Add select-field as group...
      if (is_required) justValidateInstance.addRequiredGroup(f, errorMsgs.fieldGroup);
    } else if (type === "file") {
      // Compose & add file-field rules...
      const rules = [...validationRules.files];
      if (is_required) rules.push({ rule: "minFilesCount", value: 1, errorMessage: errorMsgs.required });
      justValidateInstance.addField(inputEl, rules);
    } else if (type === "number") {
      // Compose field rules...
      const rules = [];
      if (is_required) rules.push({ rule: "required", errorMessage: errorMsgs.required });
      rules.push({
        validator: (VAL) => {
          // Setup...
          const { mustBeSumOf } = f.dataset;
          const summandIDs = JSON.parse(mustBeSumOf);
          const has_summands = summandIDs?.length > 0;

          // If field value must be sum of other fields, validate accordingly...
          if (has_summands) {
            const summands = fields.filter((el) => JSON.parse(mustBeSumOf).includes(el.dataset.id));
            const sum = summands.reduce((acc, cur) => acc + Number(cur.querySelector("input").value), 0);
            return sum === Number(VAL);
          } else return true;
        },
        errorMessage: errorMsgs.wrongSum,
      });

      // Add number-field w/ rules...
      justValidateInstance.addField(inputEl, rules);
    }
  });

  // Add field groups to instance...
  fieldGroups.forEach((fg) => {
    const is_required = fg.dataset.isRequired === "true";
    if (is_required) justValidateInstance.addRequiredGroup(fg, errorMsgs.fieldGroup);
  });

  // Event handling...
  justValidateInstance.onValidate((VALIDATIONDATA) => {
    // Set status...
    this.logger("action", loggerMsgs.validating, "action", { inline: true });
    const { isValid } = VALIDATIONDATA;
    const is_valid = isValid !== undefined && isValid === true;
    this.setState({ status: is_valid ? "valid" : "invalid" });
  });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
  return justValidateInstance;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
