// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import make_UUIDv4 from "../../../../../app/baseUtilities/make/make_UUIDv4";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_submit", inline: true };
const loggerMsgs = {
  eventStart: ["submit-button was clicked"],
  abort_formInvalid: ["submit-button was clicked", "form is invalid", "aborting submission"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_submit() {
  // Setup...
  const { fields, submitButton } = this.ref;
  const { files, submitAttempts } = this.state;
  const validator = this.modules.justValidate.instance;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Increment submit attempts...
  this.setState({ submitAttempts: submitAttempts + 1 });

  // Validate before submission...
  validator.revalidate().then((is_valid) => {
    // Guard...
    if (!is_valid) {
      // Set notification states...
      const notifStates_new = {
        invalidField: { is_active: true },
        submissionSuccess: { is_active: false },
        gmail: { is_active: false },
      };
      this.setState({ notifStates: notifStates_new });

      return this.logger("event", loggerMsgs.abort_formInvalid, "error", { inline: true });
    }

    // Config. request...
    const route = "/forderprogramm/literaturstipendiumsantrag";
    const request = new XMLHttpRequest();
    const data = new FormData();

    // Build submission data...
    data.append("id", make_UUIDv4());

    // Add text field content...
    const textFieldTypes = ["text", "textarea", "email", "phone", "number"];
    const textFields = fields.filter((f) => textFieldTypes.includes(f.dataset.type));
    textFields.forEach((f) => {
      const { id, type } = f.dataset;
      const fieldValue = type === "textarea" ? f.querySelector("textarea").value : f.querySelector("input").value;
      data.append(id, fieldValue);
    });

    // Add checkbox field content...
    const checkboxFields = fields.filter((f) => f.dataset.type === "checkbox");
    checkboxFields.forEach((f) => {
      const checkbox = f.querySelector("input");
      data.append(f.dataset.id, checkbox.checked);
    });

    // Add select field content...
    const selectFields = fields.filter((f) => f.dataset.type === "select");
    selectFields.forEach((f) => {
      const checkboxes = f.querySelectorAll("input");
      const selectedOptions = Array.from(checkboxes)
        .map((cb) => (cb.checked ? cb.id : null))
        .filter((id) => id !== null);
      data.append(f.dataset.id, selectedOptions);
    });

    // Add files...
    const files_coverLetter = files.coverLetter;
    const files_expose = files.expose;
    const files_textSample = files.textSample;
    const files_workSample = files.workSample;
    if (files_coverLetter) files_coverLetter.forEach((f) => data.append("files_coverLetter[]", f));
    if (files_expose) files_expose.forEach((f) => data.append("files_expose[]", f));
    if (files_textSample) files_textSample.forEach((f) => data.append("files_textSample[]", f));
    if (files_workSample) files_workSample.forEach((f) => data.append("files_workSample[]", f));

    // Temporarily disable submit button...
    submitButton.setAttribute("data-is-muted", true);

    // Init. request...
    request.open("POST", route, true);

    // Record upload progress...
    request.upload.onprogress = (e) => {
      const { loaded, total } = e;
      const percent = (loaded / total) * 100;
      this.setState({ uploadProgress: percent });
    };

    // Response hdl...
    request.onreadystatechange = () => {
      if (request.readyState === 4 && request.status === 200) {
        // Check if field 'email' is a gmail address...
        const emailField = fields.find((f) => f.dataset.type === "email");
        const emailFieldValue = emailField.querySelector("input").value;
        const emailIsGmail = emailFieldValue.includes("@gmail") || emailFieldValue.includes("@googlemail");

        // Set notification states...
        const notifStates_new = {
          invalidField: { is_active: false },
          submissionSuccess: { is_active: true },
          gmail: { is_active: emailIsGmail },
        };
        this.setState({ notifStates: notifStates_new });

        // Reset form...
        this.reset_form();
        submitButton.setAttribute("data-is-muted", false);
      }
    };

    // Send request...
    request.send(data);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
