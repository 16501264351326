// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_link_click from "../eventHandlers/eh_link_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. link"],
  initSuccess: ["init. link", "init. success"],
  noValidDOMEl: ["init. link", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_link() {
  // Setup...
  const { modalID } = this.options;
  this.logger("init", loggerMsgs.initStart, "action");

  // Find modal corresponding link...
  const modalLinkEl = document.querySelector(`[data-title='modal-link'][data-id='${modalID}']`);

  // Guard...
  if (!this.validate_refEL(modalLinkEl)) return this.cancel_featureInit(loggerMsgs.noValidDOMEl);

  // Listen to click-events on modal link...
  modalLinkEl.addEventListener("click", eh_link_click.bind(this));

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
