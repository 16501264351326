// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  eventStart: ["button was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_button_click(BUTTONEL, EVENTDATA) {
  // Setup...
  const flickityInstance = this.modules.flickity.instance;
  this.logger("event", loggerMsgs.eventStart, "event", { inline: true });

  // Move slideshow...
  const direction = BUTTONEL.dataset.direction;
  if (direction === ">") flickityInstance.next();
  else if (direction === "<") flickityInstance.previous();
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
