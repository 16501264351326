// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function check_link_isPassive(LINKSTATE, ACTIVE_LINK) {
  const { id, parentId } = LINKSTATE;
  const { id: activeLinkId, parentId: activeLinkParentId } = ACTIVE_LINK;
  const is_activeLink = id === activeLinkId;
  const is_parentOfActiveLink = activeLinkParentId ? id === activeLinkParentId : false;
  const is_childOfActiveLink = parentId === activeLinkId;
  const is_siblingOfActiveLink = parentId ? parentId === activeLinkParentId : false;
  const is_passive = !is_activeLink && !is_parentOfActiveLink && !is_childOfActiveLink && !is_siblingOfActiveLink;
  return is_passive;
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMessages = {
  stateChange: (CHANGE) => [`linkStates: `, CHANGE],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_linkStates(CHANGES) {
  if (!CHANGES.linkStates) return;

  // Setup...
  const { submenuStates } = this.state;
  const { linkStates } = CHANGES;
  const activeLink = linkStates.find((linkState) => linkState.is_active);
  this.logger("state-change", loggerMessages.stateChange(linkStates));

  // Updt. submenu-states (set submenu corresponding to currently active link active)...
  let has_activeSubmenu = false;
  if (submenuStates) {
    const submenuStates_new = this.make_updtSubmenuStates(submenuStates, activeLink);
    this.setState({ submenuStates: submenuStates_new });
    has_activeSubmenu = submenuStates_new ? submenuStates_new.some((submenuState) => submenuState.is_active) : false;
  }

  // Toggle active attr. on link elements...
  linkStates.forEach((linkState) => {
    const { el, is_active } = linkState;

    // Determ. if link is passive (i.e. some submenu is active & link does not belong to it)...
    const is_passive = has_activeSubmenu ? check_link_isPassive(linkState, activeLink) : false;

    el.setAttribute("data-is-active", is_active);
    el.setAttribute("data-is-passive", is_passive);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
