// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`status: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_status(CHANGES) {
  if (!CHANGES.status) return;

  // Setup...
  const { statusIndicators_valid, submitButton } = this.ref;
  const { status } = CHANGES;
  const is_valid = status === "valid";
  const is_submitting = status === "submitting";
  this.logger("state-change", loggerMsgs.stateChange(status), "default", { inline: true });

  // Show/Hide status indicator...
  statusIndicators_valid.forEach((ind) => ind.setAttribute("data-is-active", is_valid));

  // If switching to valid state, hide invalid-field notification...
  if (is_valid)
    this.setState({
      notificationsStates: {
        invalidField: { is_active: false },
        submissionSuccess: { is_active: this.state.notificationsStates.submissionSuccess.is_active },
        gmail: { is_active: this.state.notificationsStates.gmail.is_active },
      },
    });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
