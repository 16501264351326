// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMessages = {
  eventStart: ["mouse entered grid item"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_gridItem_mouseEnter(GRIDITEMEL, EVENTDATA) {
  // Setup...
  const { REF } = this.ref;
  const { OPTION } = this.options;
  this.logger("event", loggerMessages.eventStart, "event", { inline: true });

  // Updt. active-grid-item state...
  const { index } = GRIDITEMEL.dataset;
  this.setState({ activeGridItem: index });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
