// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`appl. submission upload progress: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_uploadProgress(CHANGES) {
  if (!("uploadProgress" in CHANGES)) return;

  // Setup...
  const { uploadProgress } = CHANGES;
  const { uploadProgressBar } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(uploadProgress), "default", { inline: true });

  // Show/hide progress bar...
  uploadProgressBar.setAttribute("data-is-hidden", uploadProgress === 0 || uploadProgress === 100);

  // Updt. progress bar...
  uploadProgressBar.style.setProperty("--progress", `${uploadProgress}%`);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
