// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const msgs = {
  start: ["with-company checkbox was toggled"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_withCompanyField_change() {
  //////////////////////////////////////////// Setup /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const compEl = this.element;
  const { formField_withCompany, withCompanyFieldWrapper, formField_firstName_company, formField_lastName_company } = this.ref;
  this.logger("event", msgs.start, "event", { inline: true });

  //////////////////////////////////////////// ACTION ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // Set form field group related to accompanying person active/inactive...
  withCompanyFieldWrapper.setAttribute("data-is-disabled", !formField_withCompany.checked);

  // Set first & last name fields of accompanying person required/not required...
  formField_firstName_company.required = formField_withCompany.checked;
  formField_lastName_company.required = formField_withCompany.checked;

  // Uncheck all confirm. option fields...
  const confirmOptFields = compEl.querySelectorAll("[g-ref*='formField_confirmOption']");
  confirmOptFields.forEach((fieldEl) => (fieldEl.checked = false));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
