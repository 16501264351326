// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import log from "./log";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function logger(TYPE, MESSAGE, STYLE, OPTIONS) {
  // Setup...
  const opts = this?.options ? this.options : OPTIONS ? OPTIONS : null;
  if (!opts) {
    console.warn("no options available to logger call, aborting call");
    return null;
  }
  const name = opts.name ? opts.name : this.name ? this.name : null;
  const eventName = OPTIONS ? OPTIONS.eventName : null;
  const eventsToLog = opts.eventsToLog ? opts.eventsToLog : null;
  const printInLine = OPTIONS ? OPTIONS.inline : false;

  // Stop if event not to be logged...
  if (eventsToLog && !eventsToLog.includes(eventName) && eventName !== "*") return null;
  //                                                  ^^^^^^^^^^^^^^^^^^^^
  //                                                  ↳ wildcard to log general/syst. events

  // Stop if logging disabled...
  const run_withLogs = opts.run_withLogs;
  if (!run_withLogs || run_withLogs === "defaultValue") return null;

  // Determ. message prefix based on type...
  const message = TYPE && TYPE != "" && !["mount", "unmount", "info"].includes(TYPE) ? [TYPE, ...MESSAGE] : [...MESSAGE];

  // Get log. style from comp. options...
  const logStyles = opts.logStyles;
  const logStyle_toUse = logStyles[STYLE ? STYLE : "default"];

  // Print message...
  log(name, message, logStyle_toUse, printInLine, TYPE);
  return null;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default logger;
