// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import toggle_class from "../../../../../app/baseUtilities/toggle/toggle_class";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`notification: `, CHANGE],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_notification(CHANGES) {
  if (!("notification" in CHANGES)) return;

  // Setup...
  const { notificationsWrapper, notification_success, notification_error, notification_alreadyRegistered } = this.ref;
  const { notification } = CHANGES;
  this.logger("state-change", loggerMsgs.stateChange(notification));

  // Hide/Show notification..
  toggle_class(notificationsWrapper, "hidden", null, notification === "none");
  toggle_class(notification_success, "hidden", null, notification != "success");
  toggle_class(notification_error, "hidden", null, notification != "error");
  toggle_class(notification_alreadyRegistered, "hidden", null, notification != "alreadyRegistered");
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
