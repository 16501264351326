// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`change: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeFilter(CHANGES) {
  // Setup...
  const { filterButtons } = this.ref;
  const { activeFilter } = CHANGES;
  this.logger("state-change", loggerMsgs.stateChange(activeFilter), "default", { inline: true });

  // Updt. active attr. on filter buttons...
  filterButtons.forEach((BUTTONEL) => {
    const { filter } = BUTTONEL.dataset;
    BUTTONEL.setAttribute("data-is-active", filter === activeFilter);
  });

  // Notify other components...
  eventbus.emit("CardGridFilterBar_stCh_activeFilter", { activeFilter });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
