// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_selectField_change", inline: true };
const loggerMsgs = {
  eventStart: ["select field has changed"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_selectField_change(FIELDEL, EVENTDATA) {
  // Setup...
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // If field has select limit, deselect other options...
  const selectLimit = parseInt(FIELDEL.dataset.selectLimit);
  if (!selectLimit) return;
  const clickedOpt = EVENTDATA.target;
  const otherOpts = [...FIELDEL.querySelectorAll("input[type='checkbox']")].filter((el) => el !== clickedOpt);
  if (clickedOpt.checked && otherOpts.length >= selectLimit) otherOpts.forEach((el) => (el.checked = false));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
