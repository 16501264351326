// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_selectField_change from "../eventHandlers/eh_selectField_change.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_selectFields", inline: true };
const loggerMsgs = {
  initStart: ["init. selectFields"],
  initSuccess: ["init. selectFields", "init. success"],
  noValidDOMEl: ["init. selectFields", "no valid DOM element(s) provided"],
  noSelectFields: ["init. select-fields", "no select-fields found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_selectFields() {
  // Setup...
  const { fields } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (fields.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Listen to change events on select fields...
  const selectFields = fields.filter((el) => el.dataset.type === "select");
  if (!selectFields.length) return this.cancel_featInit(loggerMsgs.noSelectFields);
  selectFields.forEach((el) => el.addEventListener("change", eh_selectField_change.bind(this, el)));

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
