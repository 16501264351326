// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component.js";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus.js";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger.js";
import validate_refEl from "../../../../app/baseUtilities/validate/validate_refEl.js";
import cancel_featureInit from "../../../../app/baseUtilities/cancel/cancel_featureInit.js";
import cancel_ebh from "../../../../app/baseUtilities/cancel/cancel_ebh.js";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_links from "./init/init_links.js";
import init_submenus from "./init/init_submenus.js";
import init_searchBar from "./init/init_searchBar.js";
import init_mobileMenu from "./init/init_mobileMenu.js";

// ——————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS/API ——————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_window_resize from "./eventbusHandlers/ebh_window_resize.js";
import ebh_swup_page_load from "./eventbusHandlers/ebh_swup_page_load.js";

import stChH_mode from "./stateChangeHandlers/stChH_mode.js";
import stChH_pageTitle from "./stateChangeHandlers/stChH_pageTitle.js";
import stChH_linkStates from "./stateChangeHandlers/stChH_linkStates.js";
import stChH_submenuStates from "./stateChangeHandlers/stChH_submenuStates.js";
import stChH_mobileMenuState from "./stateChangeHandlers/stChH_mobileMenuState.js";
import stChH_searchBarHidden from "./stateChangeHandlers/stChH_searchBarHidden.js";
import stChH_searchBarState from "./stateChangeHandlers/stChH_searchBarState.js";
import stChH_pageTitleHidden from "./stateChangeHandlers/stChH_pageTitleHidden.js";
import stChH_hidden from "./stateChangeHandlers/stChH_hidden.js";

import make_updtLinkStates from "./api/make_updtLinkStates.js";
import make_updtSubmenuStates from "./api/make_updtSubmenuStates.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Options with case-sensitive keys that are not to be
// automatically extracted from the options arg. but be
// assigned to an option key manually to preserve its case
// (Kirby CMS converts all fields/option keys to lowercase)
// (see constructor).

const manualOptionKeys = ["optionkey"];

//  Default values for manually extracted options
//  (see constructor, in case specific option has not been provided
//  in comp. config.).

const defaultOptions = {
  optionkey: { foo: "bar" },
};

// Default log styles

const defaultLogStyles = {
  default: "#6682d6",
  action: "#c7d0ff",
  event: "#97a5ce",
  warning: "#ffaf00",
  error: "#ff3232",
  success: "#00c853",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class Nav_PROTO extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element, options) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {
      links: [],
      submenus: [],
      openSubmenuBtns: [],

      mobileMenu: null,
      mobilePageTitleTextEl: null,
      mobileMenuButton: null,
      mobileMenuWrapper: null,

      searchBar: null,
      searchBarButton: null,
      searchBarInputWrapper: null,
      pageTitleTextEl: null,
      mobilePageTitleTextEl: null,
    };

    //////////// Options /////////////
    //////////////////////////////////

    // Get options not to be manually extracted from the options arg...
    const autoOptions = {};
    for (const key in options) if (!manualOptionKeys.includes(key)) autoOptions[key] = options[key];

    // Get navigation data from local storage...
    const navData = JSON.parse(localStorage.getItem("navData"));
    const mainMenuLinkData = navData?.mainMenu?.links;

    this.options = {
      name: "Nav_PROTO",
      version: element.getAttribute("g-version") ?? "1",
      // optionKey: options.optionkey,
      ...autoOptions,
      linkData: mainMenuLinkData,
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);
    this.validate_refEl = validate_refEl.bind(this);
    this.cancel_featInit = cancel_featureInit.bind(this);
    this.cancel_ebh = cancel_ebh.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    this.init_mobileMenu = init_mobileMenu.bind(this);
    this.init_submenus = init_submenus.bind(this);
    this.init_searchBar = init_searchBar.bind(this);
    this.init_links = init_links.bind(this);

    ///////////// Modules //////////////
    ////////////////////////////////////

    // ...

    /////////////// API ////////////////
    ////////////////////////////////////

    this.api = {
      make_updtSubmenuStates: make_updtSubmenuStates.bind(this),
      make_updtLinkStates: make_updtLinkStates.bind(this),
      hideComp: () => this.setState({ hidden: true }),
      showComp: () => this.setState({ hidden: false }),
    };

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_swup_page_load = ebh_swup_page_load.bind(this);
    this.ebl_window_resize = ebh_window_resize.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_submenuStates = stChH_submenuStates.bind(this);
    this.stChL_linkStates = stChH_linkStates.bind(this);
    this.stChL_searchBarState = stChH_searchBarState.bind(this);
    this.stChL_pageTitleHidden = stChH_pageTitleHidden.bind(this);
    this.stChL_hidden = stChH_hidden.bind(this);
    this.stChL_searchBarHidden = stChH_searchBarHidden.bind(this);
    this.stChL_pageTitle = stChH_pageTitle.bind(this);
    this.stChL_mobileMenuState = stChH_mobileMenuState.bind(this);
    this.stChL_mode = stChH_mode.bind(this);

    ////// Custom event handlers ///////
    // (To be passed to parent class) //

    // ...

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    this.logger("info", ["mounting"], "action", { eventName: "mount", inline: true });
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    this.logger("info", ["unmounting"], "action", { eventName: "unmount", inline: true });

    /////////////////////////////
    // Listener deregistration //
    /////////////////////////////

    eventbus.off("window_resize", this.ebl_window_resize);

    /////////////////////////////
    // API call deregistration //
    /////////////////////////////

    eventbus.off("Nav-proto.api.make_updtSubmenuStates", this.api.make_updtSubmenuStates);
    eventbus.off("Nav-proto.api.make_updtLinkStates", this.api.make_updtLinkStates);
    eventbus.off("Nav_PROTO.api.hideComp", this.api.hideComp);
    eventbus.off("Nav_PROTO.api.showComp", this.api.showComp);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.init_mobileMenu();
    this.init_submenus();
    this.setState({ mode: "init" });
    this.init_searchBar();
    this.init_links();
    this.init_states();
    this.init_eventbus();
    this.setState({ mode: "ready" });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    this.logger("init", ["states"], "action", { eventName: "init_states", inline: true });
    this.setState({ is_mobile: window.innerWidth < 640 });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { eventName: "init_eventbus", inline: true });

    ///////////////////////////
    // Listener registration //
    ///////////////////////////

    eventbus.on("swup_page_load", this.ebl_swup_page_load);
    eventbus.on("window_resize", this.ebl_window_resize);

    ///////////////////////////
    // API call registration //
    ///////////////////////////

    eventbus.on("Nav-proto.api.make_updtSubmenuStates", this.api.make_updtSubmenuStates);
    eventbus.on("Nav-proto.api.make_updtLinkStates", this.api.make_updtLinkStates);
    eventbus.on("Nav_PROTO.api.hideComp", this.api.hideComp);
    eventbus.on("Nav_PROTO.api.showComp", this.api.showComp);
  }

  /////////////////////////// State management ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    if ("submenuStates" in CHANGES) this.stChL_submenuStates(CHANGES);
    if ("linkStates" in CHANGES) this.stChL_linkStates(CHANGES);
    if ("searchBarState" in CHANGES) this.stChL_searchBarState(CHANGES);
    if ("pageTitleHidden" in CHANGES) this.stChL_pageTitleHidden(CHANGES);
    if ("hidden" in CHANGES) this.stChL_hidden(CHANGES);
    if ("searchBarHidden" in CHANGES) this.stChL_searchBarHidden(CHANGES);
    if ("pageTitle" in CHANGES) this.stChL_pageTitle(CHANGES);
    if ("mobileMenuState" in CHANGES) this.stChL_mobileMenuState(CHANGES);
    if ("mode" in CHANGES) this.stChL_mode(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default Nav_PROTO;
