// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_gridItem_mouseEnter from "../eventHandlers/eh_gridItem_mouseEnter";
import eh_gridItem_mouseLeave from "../eventHandlers/eh_gridItem_mouseLeave";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. grid-items"],
  initSuccess: ["init. grid-items", "init. success"],
  noValidDOMEl: ["init. grid-items", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_gridItems() {
  // Setup...
  const { gridItems } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (gridItems.forEach((item) => !this.validate_refEL(item))) return this.cancel_featureInit(loggerMsgs.noValidDOMEl);

  // Listen to mouse events on grid items...
  gridItems.forEach((item) => {
    item.addEventListener("mouseenter", eh_gridItem_mouseEnter.bind(this, item));
    item.addEventListener("mouseleave", eh_gridItem_mouseLeave.bind(this, item));
  });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
