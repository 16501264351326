// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_slide_click", inline: true };
const loggerMsgs = {
  eventStart: ["slide was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_slide_click(EVENTDATA) {
  //////////////////////////////// Setup /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { slides } = this.ref;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  //////////////////////////////// ACTION ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Increment active/current slide index (or cycle back to first)...
  const slideIndex_max = slides.length - 1;
  const { activeSlideIndex } = this.state;
  const slideIndex_new = activeSlideIndex < slideIndex_max ? activeSlideIndex + 1 : 0;
  this.setState({ activeSlideIndex: slideIndex_new });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
